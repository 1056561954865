import React from 'react'
import Feature from '../../components/feature/Feature';
import './genesiis.css';

const Genesiis = () => {
  return (
    <div className="swiitverse__whatgpt3 section__margin" id="genesiis">
      <div className="swiitverse__whatgpt3-heading">
        <h1 /* className="gradient__text" */>La collection Genesiis : La première collection NFT du Swiitverse</h1>
        <p><strong>LA PLUS GRANDE COMMUNAUTÉ D'AFFAIRE NUMÉRIQUE DU SPORT</strong></p>
        <div className="swiitverse__whatGPT3-heading-text">
          <p>Chaque détenteur d’une ou de plusieurs cartes Genesiis aura le statut de pionnier du SWIITVERSE sportif.
            <br />Dans l’application SWIIT, la certification « pionnier » donnera accès à plusieurs avantages , dans l’app comme dans la vraie vie via la participation à des tirages au sort et gains de récompenses (CashBack, cadeaux, trophées, accès à des événements sportifs et privés, invitation à des challenges « pionniers etc…).
          </p>
        </div>
      </div>
      <div className="swiitverse__whatgpt3-container">

        <div className="swiitverse__features-container__feature">
          <div className="swiitverse__features-container__feature-title">
            <div />
            <h1>Informations Générales</h1>
          </div>
          <div className="swiitverse__features-container_feature-text">
            <p>5555 cartes avec numéros de série unique :
              <br />Premium 3055 cartes
              <br />Silver 1388 cartes
              <br />Gold 722 cartes
              <br />Platinium 277 cartes
              <br />Diamand 113
            </p>
          </div>
        </div>

        <Feature title="Exemple de Rewards" text="L’un de nos nombreux rewards sera 5 bagues MVP Genesiis pour les détenteurs des cartes numéro 55 de chaque niveau de cartes (Premium, Silver, Gold, Platinum et Diamand). Lorsque nous créerons des challenges SWIIT sur l’app, les meilleurs challengers se verront offrir une bague personnalisée comme l'on peut trouver dans des championnats majeurs aux USA.
" />

        <div className="swiitverse__features-container__feature">
          <div className="swiitverse__features-container__feature-title">
            <div />
            <h1>Mint</h1>
          </div>
          <div className="swiitverse__features-container_feature-text">
            <p>Été 2022 vente privée
              <br />Été 2022 vente publique
              <br />MINT sur la blockchain ETH
            </p>
          </div>
        </div>
      </div>

      <div className="swiitverse__possibility-video">
        <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/MnjqIs62W0Y?&playlist=MnjqIs62W0Y&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
      </div>

    </div>
  )
}

export default Genesiis