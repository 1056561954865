import React from 'react'
import './swiitverse.css';

const SwiitverseEng = () => {
  return (
    <div className="swiitverse__swiitverse section__margin" id="swiitverse">
      {/* <div className="gpt3__whatgpt3-feature">
        <Feature title="Swiitverse" text="La pratique du sport est un enjeu sanitaire fondamentale. 
Enfin le SWIITverse deviendra le premier village digital du Metaverse dans lequel chaque club, coachs, marques, équipementiers, associations sportives auront la possibilité de créer leur propre espace 3.0
" />
      </div> */}
      <div className="swiitverse__swiitverse-feature">
      <div className="swiitverse__swiitverse-title">
          <h1>Swiitverse</h1>
        </div>
        <div className="swiitverse__swiitverse-description">
          <div className="swiitverse__swiitverse-description-title">
            <div />
            <h1>Metaverse of sport</h1>
          </div>
          <div className="swiitverse__swiitverse-description-text">
            <p>The practice of sport is a fundamental health issue.
              <br />With the development of the Metaverse, isn't there a social and health issue linked to the practice of sport "alone" in your living room?
              <br />We think so and for this we have conceived the idea of the SWIITVerse
              <br />It is therefore a question of promoting and rewarding the practice of sport in real life by creating the gateway to the future sports Metaverse.
              <br />We have imagined many connections going to the world of gaming, Everything being linked, we can say that one of our objectives will be to offer gamers a new way to progress and obtain advantages in their video games, through physical practice in real life.
              <br />Finally the SWIITverse will become the first digital village of the Metaverse in which each club, coaches, brands, equipment manufacturers, sports associations will have the possibility of creating their own 3.0 space.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SwiitverseEng