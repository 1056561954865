import React, { useState } from "react";
import './header.css';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/58fe9d70-c497-11ec-a557-034a17e2da28";


const HeaderEng = () => {

  const [status, setStatus] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Anything you need to inject dynamically
    const injectedData = {
      DYNAMIC_DATA_EXAMPLE: 123,
    };
    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    Object.assign(data, injectedData);

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // It's likely a spam/bot request, so bypass it to validate via captcha
        if (response.status === 422) {
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.target.appendChild(el);
          });

          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => setStatus("We'll be in touch soon."))
      .catch((err) => setStatus(err.toString()));
  };

  if (status) {
    return (
      <>
        <div className="swiitverse__header section__padding" id="home">
          <div className="swiitverse__header-content">
            <h1>The first digital sports village 3.0</h1>
            <p>Revolutionize the practice of sport with the <strong style={{ color: 'white' }}>"Train to Earn"</strong>. The more I play sports in real life, the more rewards and money (or digital assets) I earn.
              <br />This is one of SWIIT's promises.
            </p>
            <p>For 2 years we have developed the first digital sports village in the world.<br />A social and interactive platform where professionals and athletes develop together <strong style={{ color: 'white' }}>the economy of sport 3.0</strong>.</p>
            <p>SWIIT makes the practice of sport a powerful tool for social encounter as well as a motivation engine through the spirit of competition and the use of Train to Earn. It's time to live a new sports experience and have full access to the sports world in the palm of your hand.</p>
            <div className="swiitverse__header-content__input">
              <form
                action={FORM_ENDPOINT}
                onSubmit={handleSubmit}
                method="POST"
                target="_blank"
              >
                <input type="email" placeholder="Your Email" name="email" required />
                <button type="submit"> Get Started </button>
              </form>
            </div>
            <p>Thank you for joining us! {status}</p>
          </div>
        </div>
      </>
    );
  }



  return (
    <div className="swiitverse__header section__padding" id="home">
      <div className="swiitverse__header-content">
        <h1>The first digital sports village 3.0</h1>
        <p>Revolutionize the practice of sport with the <strong style={{ color: 'white' }}>"Train to Earn"</strong>. The more I play sports in real life, the more rewards and money (or digital assets) I earn.
          <br />This is one of SWIIT's promises.
        </p>
        <p>For 2 years we have developed the first digital sports village in the world.<br />A social and interactive platform where professionals and athletes develop together <strong style={{ color: 'white' }}>the economy of sport 3.0</strong>.</p>
        <p>SWIIT makes the practice of sport a powerful tool for social encounter as well as a motivation engine through the spirit of competition and the use of Train to Earn. It's time to live a new sports experience and have full access to the sports world in the palm of your hand.</p>
        {/* <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p> */}

        {/*         <div className="swiitverse__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div> */}

        <div className="swiitverse__header-content__input">
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
          >
            <input type="email" placeholder="Your Email" name="email" required />
            <button type="submit"> Get Started </button>
          </form>
        </div>


        {/* <div className="swiitverse__header-content__people">
          <img src={people} />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>

      {/*       <div className="swiitverse__header-image">
        <img src={temporaire} alt="presentation" />
      </div> */}
    </div>
  )
}

export default HeaderEng