import './whitelist.css';

import Navbar from "../../components/navbar/Navbar";
import React, { useState } from "react";
import { Link } from 'react-router-dom';

import { HiInformationCircle } from "react-icons/hi";
import Footer from "../footer/Footer";
import logoSwiit from '../../assets/Swiitverse_logo.svg';
const FORM_ENDPOINT = "https://public.herotofu.com/v1/e06c4bf0-d044-11ec-a821-6590c8b23a22";


const Whitelist = () => {

    const [status, setStatus] = useState();
    const [checked, setChecked] = React.useState(false);
    const handleChange = () => {
        setChecked(!checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Anything you need to inject dynamically
        const injectedData = {
            DYNAMIC_DATA_EXAMPLE: 123,
        };
        const inputs = e.target.elements;
        const data = {};

        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].name) {
                data[inputs[i].name] = inputs[i].value;
            }
        }

        Object.assign(data, injectedData);

        fetch(FORM_ENDPOINT, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                // It's likely a spam/bot request, so bypass it to validate via captcha
                if (response.status === 422) {
                    Object.keys(injectedData).forEach((key) => {
                        const el = document.createElement("input");
                        el.type = "hidden";
                        el.name = key;
                        el.value = injectedData[key];

                        e.target.appendChild(el);
                    });

                    e.target.submit();
                    throw new Error("Please finish the captcha challenge");
                }

                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
            .then(() => setStatus("We'll be in touch soon."))
            .catch((err) => setStatus(err.toString()));
    };

    if (status) {
        return (
            <>
                <div className="Whitelist">
                    <div className='gradient__bg'>
                        <Navbar />
                    </div>
                    <div className="swiitverse__whitelist">
                        <div className="swiitverse__whitelist-formEnd">
                            <h1>Merci d'avoir répondu au Formulaire.
                                <br />L'équipe va l'étudier et nous vous recontacterons prochainement!
                            </h1>
                            <h4>Cliquez sur le logo pour retourner au menu</h4>
                            <Link to="/">
                                <img src={logoSwiit} alt="logo swiit" />
                            </Link>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }

    return (
        <div className="Home">
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <div className="swiitverse__whitelist" id='whitelist'>
                <div className="swiitverse__whitelist-form">
                    <form
                        action={FORM_ENDPOINT}
                        onSubmit={handleSubmit}
                        method="POST"
                        target="_blank"
                    >
                        <h1>Bienvenue sur le Formulaire de demande d'accès à la SWT White List</h1>
                        <Link to="/tutorialMetaMask"><p><HiInformationCircle  color='white' size={27} /><u><em>Tutoriel de création de wallet MetaMask</em></u></p></Link>
                        <br />
                        <label>Email :</label>
                        <input type="email" placeholder="Votre Adresse Mail" name="email" required />
                        <label>Wallet ETH (facultatif):</label>
                        <input name="Wallet" id="wallet" type="text" placeholder='Adresse de votre Wallet ETH' />
                        <label>Nom :</label>

                        <input name="Nom" id="nom" type="text" placeholder='Nom' required />
                        <label>Prénom :</label>

                        <input name="Prenom" id="prenom" type="text" placeholder='Prénom' required />
                        <label>Twitter (facultatif):</label>

                        <input name="Twitter" id="twitter" type="text" placeholder='Compte Twitter' />
                        <label>Discord (facultatif):</label>

                        <input name="Discord" id="discord" type="text" placeholder='Identifiant Discord' />
                        
                        <label>Combien de NFT souhaitez-vous minter ?</label>
                        <p><i>Maximum 10 NFT avec un prix réduit et l'exclusivité pendant 24h.</i></p>
                        <input name="MintNumber" id="mintNumber" type="text" placeholder='1 - 10' required />

                        <label>Pourquoi :</label>
                        <textarea name="Why" id="why" type="text" placeholder='Pourquoi devrions-nous vous mettre sur notre white list ?' required />
                        <div>
                            <p>J'accepte d'être contacté au sujet du projet Swiitverse</p>
                            <input name="CheckboxContact" id="checkboxContact" type="checkbox" checked={checked} onChange={handleChange} required></input>
                        </div>
                        <button type="submit">Envoyer le Formulaire</button>
                    </form>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Whitelist
