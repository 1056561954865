import Footer from "../../containers/footer/Footer"
import Navbar from "../navbar/Navbar"

import { HashLink as Link } from 'react-router-hash-link';
import './errorPage.css';

const ErrorPage = () => {
  return (
    <div className="Home">
      <div className='gradient__bg'>
        <Navbar />
      </div>
      <div className="swiitverse__whitelist">
        <div className="swiitverse__error">
          <h1>404</h1>
          <h1>Oops! Page not Found</h1>
          <Link to="/">
            <button>Go Back To Menu</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ErrorPage
