import React from 'react'
import Feature from '../../components/feature/Feature';
import { HashLink as Link } from 'react-router-hash-link';

import './features.css';

const featuresData = [
  {
    title: 'NFT Carte membre Premium',
    text: 'Récompense d\'ETH après le sold-out (25 tirages pour une valeur par gagnant de 555 €) / La carte numéro 55 après le sold out débloquera la première bague MVP (dans la vraie vie) / Acces à la white list des prochaines collections NFT/ Bonus de SWTcoin à son introduction',
  },
  {
    title: 'NFT Carte membre Silver',
    text: 'Récompense d\'ETH après le sold-out (15 tirages pour une valeur par gagnant de 1555 €) / La carte numéro 55 après le sold out débloquera la première bague MVP (dans la vraie vie) / Acces à la white list des prochaines collections NFT/ Bonus de SWTcoin à son introduction',
  },
  {
    title: 'NFT Carte membre Gold',
    text: 'Récompense d\'ETH après le sold-out (10 tirages pour une valeur par gagnant de 2555 €) / La carte numéro 55 après le sold out débloquera la première bague MVP (dans la vraie vie) / Acces à la white list des prochaines collections NFT/ Bonus de SWTcoin à son introduction / Un Accès à la billetterie et invitation VIP dans le Swiitverse',
  },
  {
    title: 'NFT Carte membre Platine',
    text: 'Récompense d\'ETH après le sold-out (3 tirages pour une valeur par gagnant de 5555 €) / La carte numéro 55 après le sold out débloquera la première bague MVP (dans la vraie vie) / Acces à la white list des prochaines collections NFT/ Bonus de SWTcoin à son introduction / Un Accès à la billetterie et invitation VIP dans le Swiitverse / une Accès à des challenges et des NFT dans le futur du train to earn',
  },
  {
    title: 'NFT Carte membre Diamant',
    text: 'Récompense d\'ETH après le sold-out (2 tirages pour une valeur par gagnant de 10555 €) / La carte numéro 55 après le sold out débloquera la première bague MVP (dans la vraie vie) / Acces à la white list des prochaines collections NFT/ Bonus de SWTcoin à son introduction / Un Accès à la billetterie et invitation VIP dans le Swiitverse / une Accès à des challenges et des NFT dans le futur du train to earn / Des invitations IRL à des Events, Gala & groupe Professionnel',
  },
];

const Features = () => {
  return (
    <div className="swiitverse__features section__padding" id="features">
      <div className="swiitverse__features-heading">
        <h1>Les Avantages obtenus par l’acquisition des NFT Genesiis:</h1>
        <Link to="/whitelist#whitelist"><p>Demander un accès à la whitelist</p></Link>
        <p>100K€ de récompenses pour nos porteurs en cash back dès Sold out sous formes de cryptomonnaies</p>
      </div>
      <div className="swiitverse__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features