import React from 'react'
import logoSwiit from '../../assets/Swiit_neg.png';
import { HashLink as Link } from 'react-router-hash-link';
import './possibility.css';

const PossibilityEng = () => {
  return (
    <div className="section__padding" id="possibility">
      <div className="swiitverse__possibility">
        <div className="swiitverse__possibility-image">
          {/* <video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/VillageSport.mp4'} type="video/mp4" />
          </video> */}
{/*           <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/1jsHFXSttWw?&playlist=1jsHFXSttWw&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe> */}

<video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/village-digital-Grandformat.mp4'} type="video/mp4" />
          </video> 

          {/*         <img src={swiitHand} alt="possibility" />*/}
        </div>
        <div className="swiitverse__possibility-content">
          <h4><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">Join the discord</a></h4>
          <h1><img src={logoSwiit} alt="logo swiit"/> a fitter world</h1>
          <p>In an increasingly dense market, where mobile application developments favor digital coaching, we have bet on the human side by providing a series of tools and modules allowing professionals in the sector to have access to a range of comprehensive tools for their coaching or club management activities.
            <br />
            Through SWIIT it is easy to develop and retain customers and participate in a gigantic concept of gamification of sports practice.
            <br />
            For the general public, SWIIT is therefore a geolocated search engine in which they can experience and consume sport in a fun way. He will be able to follow his performance, his practice history, meet other athletes in his region, find teammates and opponents, create sports content.
            <br />
            No more excuses for reduced motivation or accessibility, everyone can increase their practice while earning money through their activity on the app.
            <br />
            Physical activity will yield a gain in the app and in real life:
            <br />
            A challenge or validated training, the constitution of a guild of sportsmen, the participation in challenges in his city, his region or his country.
          </p>
          <Link to="/whitelistEng#whitelist"><h4>Ask an early access for the whitelist</h4></Link>
        </div>
      </div>

{/*       <div className="swiitverse__possibility-textfoot">
        <h2>SWIIT makes the practice of sport a powerful tool for social encounter as well as a motivation engine through the spirit of competition and the use of Train to Earn. It's time to live a new sports experience and have full access to the sports world in the palm of your hand.</h2>
      </div> */}
      <div className="swiitverse__possibility-video">
        <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/pJBhNyOsW0E?&playlist=pJBhNyOsW0E&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default PossibilityEng;