import React/* , { useState } */ from 'react';
/* import { BsCheckCircleFill, BsFillHouseDoorFill, BsCircle } from 'react-icons/bs'; */
/* import Popup from './Popup'; */
import roadmapImgFr from '../../assets/swiit_roadmap-MEP_fr.png'
import './roadmap.css';

function Roadmap() {
/*     const [isOpen, setIsOpen] = useState(false);
 */
/*     const togglePopup = () => {
        setIsOpen(!isOpen);
    } */

    return (
        <div className='swiitverse__roadmap section__padding' id="roadmap">
{/*             <div className='swiitverse__roadmap-heading'>
                <h1 className="gradient__text">Notre Roadmap</h1>
            </div> */}
            
            <div className='swiitverse__roamap-image'>
                <img src={roadmapImgFr} alt="Roadmap design" />
            </div>

        </div>
    );
};

export default Roadmap