import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Swiit from './contracts/Swiit.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';

import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';
import { SiEthereum } from "react-icons/si";

import './whiteListVerification.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";

window.Buffer = window.Buffer || Buffer;

const tokens = require("./tokens.json");
const GGaddress = "0xCf75DA7B85Ed75A370710a3d6432BFe7fc064AeC";

function WhiteListVerification() {


    const [error, setError] = useState('');
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);
    const [mintAmount, setMintAmount] = useState(1);
    const [isConnected, setIsConnected] = useState(Boolean);
    const [isWhitelistedData, setIsWhitelistedData] = useState(Boolean);
    const [wallet, setWallet] = useState('');

    useEffect(() => {
/*         fetchData(); */        
        getAccounts();
    }, [])


    const handleChange = event => {
        setWallet(event.target.value);
        //console.log('value is:', event.target.value);
    };

    async function table() {
        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const leaf = keccak256(wallet);
        //0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058
        const proof = tree.getHexProof(leaf);
        console.log("root : " + tree.getHexRoot() + " - " + "proof : " + proof);
        setIsWhitelistedData(false);
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            const walletAddr = String(wallet);
            //const walletAddr = "0x7356a11be0ff8485dee2a3057c22d70f484fa058";
            console.log("ACCOUNT à vérifier = " + walletAddr);

            try {
                const isWhitelisted = await contract.isWhitelisted(walletAddr, proof);
                console.log("Apres verif du contrat = " + isWhitelisted);
                setIsWhitelistedData(isWhitelisted);
                console.log("Storé dans la page = " + isWhitelistedData);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }


    async function setMerkleRootAdmin() {
        console.log("OK START MERKLE")
        // let whitelistAddresses = [
        //     "0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058",
        //     "0x67aB36379e0EbaF52C618c04A11b11d49B969059",
        //     "0X5A641E5FB72A2FD9137312E7694D42996D689D99",
        //     "0XDCAB482177A592E424D1C8318A464FC922E8DE40",
        //     "0X6E21D37E07A6F7E53C7ACE372CEC63D4AE4B6BD0",
        //     "0X09BAAB19FC77C19898140DADD30C4685C597620B",
        //     "0XCC4C29997177253376528C05D3DF91CF2D69061A",
        //     "0x7628768Ba013d3248A576DFe2E43188b3BC1d866",
        //     "0x58A974aCBb46FF02452003368c7E19effCb89BCe",
        //     "0xE03F8D3214420197Ed17594bc6C9f68B1167E297",
        //     "0xa7abfA25a7A62347713100c18156DA8e75b4E549",
        //     "0x1Bbfa6fFCA88ce3DF6aAD1bb1A5bA82c3aa8341e",
        //     "0x21bBcC5a66bD6A5C557c4C80169EC7410FAba8e2",
        //     "0x339c59604534Aa6bF9e3C7dBD536Ef29A26DAB11",
        //     "0xdD870fA1b7C4700F2BD7f44238821C26f7392148",
        //     "0x793DB0E996ADfD033ecD82D145011b91600A08DD",
        //     "0xf476254a465E8151723fb58F638Fe26a1Fe0fa5B",
        //     "0x0F8292d1AbC89948460570881e33459Bb2eDa9b7",
        //     "0x5972a8ad23B33a07095Eea6CCa9df3a6E2D62902",
        //     "0xa8b04Ad282C52b9490Eccf7082e3Dd9536cA168E",
        //     "0xFE5FA1C4fa346B9035502f52C2CA5608B4e807ec",
        //     "0xa38435736cD09Ca8D3B5cb5467550dA3364FdBec",
        //     "0x3850A20A9E3352a3745aA30a202f7a6422225107",
        //     "0x37d6bF7e8875137EefA8286e6AEA2cc4bFAF1247",
        //     "0x793DB0E996ADfD033ecD82D145011b91600A08DD",
        //     "0x9d8eE21Fe1e5c92a73f504FF9E7a3731A26963ca",
        //     "0x3BB6BAFf54e3929f63cB05aE4B8752C18e9E2e63",
        //     "0xf639e2480084f5dd8b85aa9dfdaeb26575a57530"
        // ];

        // const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
        // const merkleTree = new MerkleTree(leafNodes, keccak256, { sort: true });

        // const rootHash = merkleTree.getRoot();
        // console.log('Whitelist Merkle Tree\n', merkleTree.toString());
        // console.log("Root Hash: ", rootHash);
        // console.log("Root1 Hash: ", merkleTree.getHexRoot());

        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        console.log(tree.toString())
        console.log("root : " + tree.getHexRoot());
    }


    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avant = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apres = " + isConnected);

        }
    }


/* 
    async function fetchData() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const cost = await contract.publicMintLimit();
                const totalSupply = await contract.totalSupply();
                const totalSupplyWl = await contract.WLLIMIT();
                const collectionSize = await contract.MAXCOLLECTIONSIZE();
                const presaleMintLimit = await contract.presaleMintLimit();
                const presalePrice = await contract.presalePrice(1);
                const publicMintLimit = await contract.publicMintLimit();
                const publicPrice = await contract.publicPrice(1);

                const object = {
                    "cost": String(cost), "totalSupply": String(totalSupply), "totalSupplyWL": String(totalSupplyWl),
                    "collectionSize": String(collectionSize), "presaleMintLimit": String(presaleMintLimit), "presalePrice": String(presalePrice),
                    "publicMintLimit": String(publicMintLimit), "publicPrice": String(publicPrice)
                }
                setData(object);
            }
            catch (err) {
                setError(err.message);
            }
        }
    } */

    return (
        <div className="Home">
            <div className='gradient__bg'>
                <Navbar />
            </div>
  

            <div className="section__padding" id="verify-whitelist">
                <div className="swiitverse__WhitelistVerification">
                    <div className="swiitverse__possibility-content">
                        <div>
                            <h1>You can verify if you are whitelisted :</h1>
                            <input
                                type="text"
                                id="wallet"
                                name="wallet"
                                onChange={handleChange}
                                value={wallet}
                                autoComplete="off"
                                className="input-textVerifyWL"
                            />
                            <p>Your Wallet Adress ETH<SiEthereum />: {wallet}</p>
                            <p>Your adress is in the whitelist : <b>{JSON.stringify(isWhitelistedData)}</b></p>
                            {isWhitelistedData ? (
                                <>
                                    <p><b>You are whitelisted</b></p>
                                </>) : (<><p>You are not whitelisted</p></>)}
                            <button onClick={table} className="buttonVerifyWL">Verify your Adress</button>
                        </div>

                        {account[0] === "0xd16e91df86fc91567f1a4710e450dd399c3509f5" &&
                            <>
                                <p>Set setMerkleRoot Admin</p>
                                <button className="buttonWL" onClick={setMerkleRootAdmin}>Get the ROOT Admin</button>
                            </>
                        }

                    </div>



                </div>


            </div>

            <Footer />
        </div>
    )
}

export default WhiteListVerification
