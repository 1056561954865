import React from 'react'
/* import swiitHand from '../../assets/swiithand.png'*/
import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';
import './possibility.css';

const Possibility = () => {
  return (
    <div className="section__padding" id="possibility">
      <div className="swiitverse__possibility">
        <div className="swiitverse__possibility-image">
        {/* <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/1jsHFXSttWw?&playlist=1jsHFXSttWw&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
 */}
<video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/village-digital-Grandformat.mp4'} type="video/mp4" />
          </video> 

          {/* <video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/VillageSport.mp4'} type="video/mp4" />
          </video> */}
          {/*         <img src={swiitHand} alt="possibility" />*/}
        </div>
        <div className="swiitverse__possibility-content">
        <a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer"><h4>Rejoins le discord</h4></a>
          <h1 /* className="gradient__text" */><img src={logoSwiit} alt="logo swiit"/> a fitter world</h1>
          <p>Dans un marché qui se densifie, où les développements d’application mobile favorisent le coaching numérique, nous avons fait le pari de l’humain en mettant à disposition une série d’outils et modules permettant aux professionnels du secteur d’avoir accès à une palette d’outils complets pour leurs activités de coaching ou de gérance de clubs.
            <br />
            A travers SWIIT il est facile de développer et fidéliser sa clientèle et participer à un gigantesque concept de gamification de la pratique sportive.
            <br />
            Pour le grand public, SWIIT est donc un moteur de recherche géolocalisé dans lequel, il pourra vivre et consommer le sport de manière ludique. Il pourra suivre ses performances, son historique de pratique, rencontrer d’autres sportifs de sa région, trouver des coéquipiers et des adversaires, créer du contenu sportif.
            <br />
            Plus d’excuse, de baisse de motivation ou d’accessibilité, tout le monde peut augmenter sa pratique tout en se rémunérant par son activité sur l’app.
            <br />
            Vous serez récompensé dans l'app et dans la vraie vie par votre activité physique :
            <br />
            Un challenge ou un entrainement validé, la constitution d’une guilde de sportifs, la participation à des défis dans sa ville, sa région où son pays.
            <br />
            Vous l'aurez compris, valorisez votre sport au quotidien! Bienvenue sur SWIIT.
          </p>
          <Link to="/whitelist#whitelist"><h4>Demandez un accès anticipé à la white list</h4></Link>
        </div>
      </div>

{/*       <div className="swiitverse__possibility-textfoot">
        <h2>SWIIT fait de la pratique du sport, un puissant outil de rencontre sociale ainsi qu’un moteur de motivation via l’esprit de compétition et de l’utilisation du Train to Earn. Il est temps de vivre une nouvelle expérience sportive et d’avoir un accès complet au monde sportif dans le creux de votre main.</h2>
      </div> */}
      <div className="swiitverse__possibility-video">
        <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/CjvfS6jeEyM?&playlist=CjvfS6jeEyM&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
      </div>
    </div>
  )
}

export default Possibility;