import React from 'react'
import { google, slack, atlassian, dropbox, shopify } from './imports';
import './brand.css';

function Brand() {
  return (
    <div className="swiitverse__brand section__padding">
{/*     <div className="swiitverse__brand-title">
      <h1>Partners :</h1>
    </div> */}
    <div className="swiitverse__brand-logo">
      <div>
      <img src={google} alt="google"/>
    </div>
    <div>
      <img src={slack} alt="slack"/>
    </div>
    <div>
      <img src={atlassian} alt="altlassian" />
    </div>
    <div>
      <img src={dropbox}  alt="dropbox"/>
    </div>
    <div>
      <img src={shopify} alt="shopify" />
    </div>
    </div>
  </div>
  )
}

export default Brand