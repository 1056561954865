import React from 'react';
import swiitLogo from '../../assets/Swiitverse_logo.svg';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';

import './footer.css';

const FooterEng = () => {
  return (
    <div className="swiitverse__footer section__padding">
      <div className="swiitverse__footer-heading">
        <h1>Live a preview of the future of sports experiences</h1>
      </div>

      <div className="swiitverse__footer-btn">
        <a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">
          <p>JOIN The Discord</p>
        </a>
      </div>

      <div className="swiitverse__footer-links">
        <div className="swiitverse__footer-links_logo">
          <img src={swiitLogo} alt="swiitverse_logo" />
          <p>SAS Swiit<br /> All Rights Reserved</p>
        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Links</h4>
{/*           <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p> */}
          <p><a href='https://twitter.com/Swiit_Fit' target="_blank" rel="noreferrer"><BsTwitter color='white' size={27} /></a></p>
        <p><a href='https://www.instagram.com/swiitverse/' target="_blank" rel="noreferrer"><BsInstagram color='white' size={27} /></a></p>
        <p><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer"><FaDiscord color='white' size={27} /></a></p>

        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Company</h4>
          <Link  to="/mention-legale#top"><p>Terms & Conditions </p></Link>
          <Link to="/politque-de-confidentialite#top"><p>Privacy Policy</p></Link>
          {/*           <p>Contact</p>
 */}        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Get in touch</h4>
          <p>100 Rue des Métiers 83140 Six-Fours-les-Plages</p>
          <p>Phone:</p>
          <p>contact@swiit.fit</p>
        </div>
      </div>

      <div className="swiitverse__footer-copyright">
        <p>@2022 SAS Swiit. All rights reserved.</p>
      </div>
    </div>
  )
}

export default FooterEng;