import { Navbar } from '../../components';
import Footer from '../footer/Footer';

import './terms.css';

const PolitiqueConfidentialite = () => {
    return (
        <div className="Terms" id='top'>
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <div className="swiitverse__terms">
                <div className="swiitverse__terms-form">
                    <h1>Politique de Confidentialité</h1>
                    <br />
                    <label><b>Pourquoi cette déclaration de confidentialité ?</b></label>
                    <p>Si vous visitez notre site internet par intérêt, utilisez notre application ou répondez à nos publications sur les réseaux sociaux, nous avons besoin de certaines données personnelles vous concernant. Nous voulons être transparents sur le traitement de vos données personnelles, d’où cette déclaration de confidentialité.
                        SWIIT traite vos données personnelles avec le plus grand soin et respecte les lois et règlements en matière de protection des données personnelles, comme le Règlement général sur la protection des données (RGPD).
                        Nous avons répertorié ci-dessous pour vous les données que nous traitons, les bases légal utilisées , les raisons pour lesquelles nous le faisons et comment nous traitons vos données à caractère personnel. En outre, nous vous expliquons comment vous pouvez influencer le traitement de vos données à caractère personnel par SWIIT.
                        Nous sommes une entreprise dynamique dans un environnement en évolution rapide, il est donc important que nous actualisions ponctuellement notre politique de confidentialité. Nous nous réservons donc le droit de modifier cette déclaration de confidentialité à tout moment. Vous pouvez toujours trouver la version la plus récente sur notre site Internet. Nous vous conseillons donc de visiter régulièrement cette page.</p>
                    <br />
                    <label><b>Quelles données à caractère personnel traitons-nous ?</b></label>
                    <p>Nous recevons, collectons et stockons toute information que vous saisissez sur notre site web ou que vous nous fournissez de toute autre manière. En outre, nous recueillons l'adresse de protocole Internet (IP) utilisée pour connecter votre ordinateur à l'Internet ; le login ; l'adresse e-mail ; le mot de passe ; les informations sur l'ordinateur et la connexion et l'historique des achats. Nous pouvons utiliser des outils logiciels pour mesurer et collecter des informations de session, y compris les temps de réponse des pages, la durée des visites sur certaines pages, les informations sur l'interaction entre les pages et les méthodes utilisées pour naviguer hors de la page. Nous recueillons également des informations d'identification personnelle (y compris le nom, l'adresse électronique, le mot de passe, les communications) ; les détails de paiement (y compris les informations de carte de crédit), les commentaires, les réactions, les évaluations de produits, les recommandations et le profil personnel.</p>

                    <br />
                    <label><b>Comment recueillez-vous des informations ?</b></label>
                    <p>Lorsque vous effectuez une transaction sur notre site web, dans le cadre du processus, nous recueillons les informations personnelles que vous nous donnez, en autres sur la page "me tenir informé", telles que votre adresse e-mail.</p>

                    <br />
                    <label><b>À quelles fins traitons-nous vos données ?</b></label>
                    <p>Nous recueillons ces informations non personnelles et personnelles aux fins suivantes :
                        <br />1. Fournir et exploiter les services ;
                        <br />2. Fournir à nos utilisateurs une Assistance et un support technique permanents ;
                        <br />3. Pouvoir contacter nos visiteurs et utilisateurs avec des avis généraux ou personnalisés relatifs au service et des messages promotionnels ;
                        <br />4. Pour créer des données statistiques agrégées et d'autres informations non personnelles agrégées et/ou déduites, que nous ou nos partenaires commerciaux pouvons utiliser pour fournir et améliorer nos services respectifs ;
                        <br />5. Se conformer aux lois et règlements applicables.</p>

                    <br />
                    <label><b>Comment stockez-vous, utilisez, partagez-vous et divulguez-vous les informations personnelles des visiteurs de votre site ?</b></label>
                    <p>Notre entreprise est hébergée sur la plateforme OVH. Vos données peuvent être stockées par le biais du stockage de données, des bases de données et des applications générales d'OVH'. Elles stockent vos données sur des serveurs sécurisés derrière un pare-feu.</p>

                    <br />
                    <label><b>Comment gérons-nous la sécurité de vos données ?</b></label>
                    <p>SWIIT veille à prendre les mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données à caractère personnel contre, entre autres, la perte, la destruction ou l’endommagement, l’accès non autorisé ou toute autre forme de traitement illicite de vos données personnelles. Nous le faisons, entre autres, en prenant des mesures physiques et organisationnelles de sécurité d’accès, en protégeant chaque poste de travail avec un nom d’utilisateur, un mot de passe et une gestion des autorisations, afin que vos données ne puissent pas être consultées par tous les collaborateurs de SWIIT. Mais la mise à jour des logiciels et les tests réguliers de nos mesures de sécurité en font également partie.</p>
                    <br />

                    <label><b>Combien de temps conservons-nous vos données ?</b></label>
                    <p>Nous conservons vos données dans la mesure où cela est nécessaire à la réalisation des finalités pour lesquelles nous les traitons et pour une durée n’excédant pas celle autorisée par la loi. Le délai de conservation final diffère par type de données personnelles. Si vous êtes membre de SWIIT, nous conserverons en tout cas vos données pendant toute la durée de votre affiliation. Après l’expiration de la période de conservation, vos données personnelles seront anonymisées ou effacées.</p>
                    <br />

                    <label><b>Soumettre une plainte :</b></label>
                    <p>Vous avez le droit de soumettre une réclamation sur la manière dont nous traitons vos données ou que vous ne souhaitez plus que nous traitions vos données . Lorsque vous soumettez une réclamation, nous préférons la résoudre nous-même. De plus, vous avez toujours le droit de contacter l’autorité compétente qui supervise la protection des données à caractère personnel avec votre réclamation.
                        <br />Si vous souhaitez exercer l’un de vos droits, procédez comme suit :
                        Vous pouvez envoyer votre demande, par courriel à contact@swiit.fit, ou vous pouvez envoyer une demande par la poste à : 204 Rue de Nice 83100 TOULON.
                        <br />Veuillez toujours indiquer dans votre demande de quelle type de demande il s'agit: "demande d'accès", "demande de rectification", etc..., et indiquez vos nom, adresse et numéro de téléphone.
                        <br />Vous recevrez une réponse au plus tard un mois suivant la réception de la demande.</p>
                    <br />

                    <label><b>Mises à jour de la politique de confidentialité :</b></label>
                    <p>Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment, aussi nous vous invitons à la consulter fréquemment. Les modifications et les clarifications prendront effet dès leur publication sur le site web. Si nous apportons des modifications importantes à la présente politique, nous vous informerons ici de sa mise à jour, afin que vous sachiez quelles informations nous recueillons, comment nous les utilisons et dans quelles circonstances, le cas échéant, nous les utilisons et/ou les divulguons.</p>
                    <br />

                    <p>Si vous avez des questions à propos de la présente déclaration de confidentialité et à propos de la politique de SWIIT, vous pouvez vous adresser à contact@swiit.fit</p>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default PolitiqueConfidentialite
