import { Navbar } from '../../components';
import Footer from '../footer/Footer';

import './terms.css';

const Terms = () => {
  return (
    <div className="Terms" id='top'>
      <div className='gradient__bg'>
        <Navbar />
      </div>
      <div className="swiitverse__terms">
        <div className="swiitverse__terms-form">
          <h1>MENTIONS LÉGALES</h1>
          <br />
          <p>Les présentes conditions générales fixent les modalités d'utilisation du service web https://swiitverse.com</p>
          <br />
          <p>SAS SWIIT</p>
          <br />
          <p>RCS TOULON - SIREN 907941892</p>
          <br />
          <p>N°TVA : FR19907941892</p>
          <br />
          <p>Siège Social : 204, Rue de Nice les Chevreuilles 83100 TOULON</p>
          <br />
          <p>Téléphone : 06 35 35 73 60</p>
          <br />
          <p>Mail : contact@swiit.fit</p>
          <br />
          <p>Directeur de la publication : Quentin MICHEL DE PIERREDON - quentin.michel-de-pierredon@swiit.fit</p>
          <br />
          <p>Hébergeur : OVH SAS - 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1 - Téléphone : 1007</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Terms
