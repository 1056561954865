import React, { useState } from "react";
import './header.css';

const FORM_ENDPOINT = "https://public.herotofu.com/v1/58fe9d70-c497-11ec-a557-034a17e2da28";


const Header = () => {

  const [status, setStatus] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();

    // Anything you need to inject dynamically
    const injectedData = {
      DYNAMIC_DATA_EXAMPLE: 123,
    };
    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    Object.assign(data, injectedData);

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        // It's likely a spam/bot request, so bypass it to validate via captcha
        if (response.status === 422) {
          Object.keys(injectedData).forEach((key) => {
            const el = document.createElement("input");
            el.type = "hidden";
            el.name = key;
            el.value = injectedData[key];

            e.target.appendChild(el);
          });

          e.target.submit();
          throw new Error("Please finish the captcha challenge");
        }

        if (response.status !== 200) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(() => setStatus("We'll be in touch soon."))
      .catch((err) => setStatus(err.toString()));
  };

  if (status) {
    return (
      <>
    <div className="swiitverse__header section__padding" id="home">
      <div className="swiitverse__header-content">
        <h1>Le premier village sportif digital 3.0</h1>
        <h3><i>Révolutionner la pratique du sport par le <strong style={{ color: 'white' }}>«Train to Earn»</strong>.</i></h3>
        <p>Plus je pratique du sport dans la vraie vie, plus je gagne des récompenses et de l’argent (ou actifs numériques).
          <br />Telle est l’une des promesses de SWIIT.
        </p>
        <p>Depuis 2 ans nous avons développé le premier village digital sportif au monde.<br />Une plateforme sociale et interactive où professionnels et sportifs développent ensemble <strong style={{ color: 'white' }}>l’économie du sport 3.0</strong>.</p>
        <p>SWIIT fait de la pratique du sport, un puissant outil de rencontre sociale ainsi qu’un moteur de motivation via l’esprit de compétition et de l’utilisation du Train to Earn. Il est temps de vivre une nouvelle expérience sportive et d’avoir un accès complet au monde sportif dans le creux de votre main.</p>
        <div className="swiitverse__header-content__input">
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
          >
            <input type="email" placeholder="Votre Adresse Mail" name="email" required />
            <button type="submit"> Abonnez-vous </button>
          </form>
        </div>
        <p>Merci de nous rejoindre! {status}</p>
      </div>
    </div>
      </>
    );
  }

  return (
    <div className="swiitverse__header section__padding" id="home">
      <div className="swiitverse__header-content">
        <h1>Le premier village sportif digital 3.0</h1>
        <h3><i>Révolutionner la pratique du sport par le <strong style={{ color: 'white' }}>«Train to Earn»</strong>.</i></h3>
        <p>Plus je pratique du sport dans la vraie vie, plus je gagne des récompenses et de l’argent (ou actifs numériques).
          <br />Telle est l’une des promesses de SWIIT.
        </p>
        <p>Depuis 2 ans nous avons développé le premier village digital sportif au monde.<br />Une plateforme sociale et interactive où professionnels et sportifs développent ensemble <strong style={{ color: 'white' }}>l’économie du sport 3.0</strong>.</p>
        <p>SWIIT fait de la pratique du sport, un puissant outil de rencontre sociale ainsi qu’un moteur de motivation via l’esprit de compétition et de l’utilisation du Train to Earn. Il est temps de vivre une nouvelle expérience sportive et d’avoir un accès complet au monde sportif dans le creux de votre main.</p>
        {/* <p>Yet bed any for travelling assistance indulgence unpleasing. Not thoughts all exercise blessing. Indulgence way everything joy alteration boisterous the attachment. Party we years to order allow asked of.</p> */}

        {/*         <div className="swiitverse__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div> */}

        <div className="swiitverse__header-content__input">
          <form
            action={FORM_ENDPOINT}
            onSubmit={handleSubmit}
            method="POST"
            target="_blank"
          >
            <input type="email" placeholder="Votre Adresse Mail" name="email" required />
            <button type="submit"> Abonnez-vous </button>
          </form>
        </div>


        {/* <div className="swiitverse__header-content__people">
          <img src={people} />
          <p>1,600 people requested access a visit in last 24 hours</p>
        </div> */}
      </div>

{/*       <div className="swiitverse__header-image">
        <img src={temporaire} alt="presentation" />
      </div>
 */}


    </div>
  )
}

export default Header