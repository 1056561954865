import './home.css';

import HeaderEng from "../header/HeaderEng";
import NavbarEng from "../../components/navbar/NavbarEng";
import PossibilityEng from "../possibility/PossibilityEng";
import FeaturesEng from "../features/FeaturesEng";
import FaqEng from "../FAQ/FaqEng";
import { CTAEng } from "../../components";
import TeamsEng from "../teams/TeamsEng";
import FooterEng from "../footer/FooterEng";
import RoadmapEng from "../roadmap/RoadmapEng";
import SwiitverseEng from '../swiitverse/SwiitverseEng';
import GenesiisEng from '../genesiis/GenesiisEng';
import RevealEng from '../Reveal/RevealEng';
import CTAWhitepaperEng from '../../components/ctaWhitepaper/CTAWhitepaperEng';


function HomeEng() {
  return (
    <div className="HomeEng">
      <div className='gradient__bg'>
        <NavbarEng />
        <HeaderEng />
      </div>
      {/*no <Brand /> */}
      {/*no <WhatGPT3 /> */}
      <PossibilityEng />
      
      <SwiitverseEng />
      <GenesiisEng />
      <RevealEng />
      {/*no <Card /> */}
      <FeaturesEng />
      {/* <CTAWhitepaperEng></CTAWhitepaperEng> */}
{/*       <RoadmapEng /> */}
      <FaqEng />
      <CTAEng />

      
      {/*no need <Blog /> */}


      <TeamsEng />
      <FooterEng />
    </div>
  )
}

export default HomeEng
