/* import { Brand } from '../../components'
 *//* import { Brand, CTA, Navbar } from '../../components'
import { Footer, Blog, Possibility, Features, WhatGPT3, Header,  Card,  Roadmap, Faq, Teams } from '../../containers'; */
import './home.css';

import CTA from "../../components/cta/CTA";
import Navbar from "../../components/navbar/Navbar";

import Footer from "../footer/Footer";
import Possibility from "..//possibility/Possibility";
import Features from "../features/Features";
import Header from "../header/Header";
import Roadmap from "../roadmap/Roadmap";
import Faq from "../FAQ/Faq";
import Teams from "../teams/Teams";

import Swiitverse from '../swiitverse/Swiitverse';
import Genesiis from '../genesiis/Genesiis';
import Reveal from '../Reveal/Reveal';
import CTAWhitepaper from '../../components/ctaWhitepaper/CTAWhitepaper';
// import Brand from '../../components/brand/Brand';

function Home() {
  return (
    <div className="Home">
      <div className='gradient__bg'>
        <Navbar />
        <Header />
      </div>
      {/*no <WhatGPT3 /> */}
      <Possibility />
      <Swiitverse />
      <Genesiis />
      {/*no <Card /> */}
      <Reveal />
      <Features />
      {/* <CTAWhitepaper /> */}
  {/*     <Roadmap /> */}
      <Faq />
      <CTA />

      
      {/*no need <Blog /> */}


      <Teams />
{/*       <Brand />
 */}      <Footer />
    </div>
  )
}

export default Home
