import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
/* import swiitHand from '../../assets/swiithand.png'*/
import './reveal.css';

const Reveal = () => {
  return (
    <div className="section__padding" id="reveal">
      <div className="swiitverse__possibility">
        <div className="swiitverse__possibility-image">
          {/* <video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/Reveal.mp4'} type="video/mp4" />
          </video> */}
                <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ulRzhS7vPl0?&playlist=ulRzhS7vPl0&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
          {/*         <img src={swiitHand} alt="possibility" />*/}
        </div>
        <div className="swiitverse__possibility-content">
          <h4><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">Rejoins le discord</a></h4>
          <h1 /* className="gradient__text" */>GENESIS PRE-REVEAL NFT</h1>
          <p>La black SwiitCard pre reveal.
            <br />48h après le début de la public sale, vos cartes pre-reveals se transformeront en l'une des possibles swiit Card parmi les 5 grandes catégories, chacune avec un numéro unique dont vous serez propriétaire.
            <br />Arriverez-vous à deviner derrière quels numéros se cachent les plus grosses récompenses ?
          </p>
          <Link to="/whitelist#whitelist"><h4>Demandez un accès anticipé à la white list</h4></Link>
        </div>
      </div>
    </div>
  )
}

export default Reveal;