import React from 'react'
import Feature from '../../components/feature/Feature';
import './faq.css';

const featuresData = [
  {
    title: 'WHAT IS THE MINT PRICE?',
    text: ': 0,15 ETH + gas fee',
  },
  {
    title: 'What are your secondary royalty fees?',
    text: '10.55%',
  },
  {
    title: 'Total number of tokens:',
    text: '5.555 NFT : 3055 premium / 1388 silver / 722 gold / 277 platimium / 113 Diamond',
  },
  {
    title: 'Blockchain & MarketPlace:',
    text: 'ETHEREUM & OPENSEA',
  },
];

const FaqEng = () => {
  return (
    <div className="swiitverse__features section__padding" id="faq">
      <div className="swiitverse__features-heading">
        <h1>FAQ:</h1>
        <p>Have any questions?</p>
        <p>The best place to find out more about SWIITVERSE is on Discord</p>
        <p>There’s always someone around to help out whenever you need it.</p>
      </div>
      <div className="swiitverse__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default FaqEng