import React from 'react'
import './cta.css';

const CTAEng = () => (
  <div className="swiitverse__cta">
    <div className="swiitverse__cta-content">
      <p>Join the Discord to Get Started</p>
      <h3>Join us Today & start exploring the endless possibilities. JOIN THE COMMUNITY NOW</h3>
    </div>
    <div className="swiitverse__cta-btn">
      <a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">
        <button type="button">Get Started</button>
      </a>
    </div>
  </div>
);

export default CTAEng;