import React from 'react'
import Feature from '../../components/feature/Feature';
import './genesiis.css';

const GenesiisEng = () => {
  return (
    <div className="swiitverse__whatgpt3 section__margin" id="genesiis">
      <div className="swiitverse__whatgpt3-heading">
        <h1>The Genesiis Collection: The Swiitverse's First NFT Collection</h1>
        <p><strong>THE LARGEST DIGITAL BUSINESS COMMUNITY IN SPORT</strong></p>
        <div className="swiitverse__whatGPT3-heading-text">
          <p>Each holder of one or more Genesiis cards will have the status of a sports SWIITVERSE pioneer.
            <br />In the SWIIT application, the "pioneer" certification will give access to several advantages, in the app as well as in real life via participation in sweepstakes and winning rewards (CashBack, gifts, trophies, access to sporting events and private, invitation to "pioneer" challenges, etc.).
          </p>
        </div>
      </div>
      <div className="swiitverse__whatgpt3-container">

        <div className="swiitverse__features-container__feature">
          <div className="swiitverse__features-container__feature-title">
            <div />
            <h1>General informations</h1>
          </div>
          <div className="swiitverse__features-container_feature-text">
            <p>5555 cards with unique serial numbers:
              <br />Premium 3055 cards
              <br />Silver 1388 cards
              <br />Gold 722 cards
              <br />Platinium 277 cards
              <br />Diamand 113 cards
            </p>
          </div>
        </div>

        <Feature title="Example of Rewards" text="One of our many rewards will be 5 MVP Genesiis rings for holders of card number 55 of each card level (Premium, Silver, Gold, Platinum and Diamond). When we create SWIIT challenges on the app, the best challengers will be offered a personalized ring as found in major championships in the USA." />

        <div className="swiitverse__features-container__feature">
          <div className="swiitverse__features-container__feature-title">
            <div />
            <h1>Mint</h1>
          </div>
          <div className="swiitverse__features-container_feature-text">
            <p>Summer 2022 private sale
              <br />Summer 2022 public sale
              <br />MINT on the ETH blockchain
            </p>
          </div>
        </div>
      </div>

      <div className="swiitverse__possibility-video">
        <iframe width="720" height="405" src="https://www.youtube-nocookie.com/embed/MnjqIs62W0Y?&playlist=MnjqIs62W0Y&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
      </div>

    </div>
  )
}

export default GenesiisEng