import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Swiit from './contracts/Swiit.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';

import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';

import './MintTemporaire.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";


import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";

import ai from '../../assets/mint/Swiit-NFT-Genesiis-cartes.png';


window.Buffer = window.Buffer || Buffer;

const tokens = require("./tokens.json");
const GGaddress = "0xCf75DA7B85Ed75A370710a3d6432BFe7fc064AeC";

function MintTemporaire() {


    const [error, setError] = useState('');
    const [TestError, setTestError] = useState({});
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);
    const [mintAmount, setMintAmount] = useState(1);
    const [isConnected, setIsConnected] = useState(false);
    const [isWhitelistedData, setIsWhitelistedData] = useState(Boolean);
    const [wallet, setWallet] = useState('');
    const [price, setPrice] = useState('');
    const [balance, setBalance] = useState('');

    useEffect(() => {
        console.log("KKK = " + isConnected);
        fetchData();
        //getAccounts();
        console.log("AAA = " + isConnected);
    }, [])

    const handleChange = event => {
        setWallet(event.target.value);
        //console.log('value is:', event.target.value);
    };

    async function table() {
        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const leaf = keccak256(wallet);
        //0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058
        const proof = tree.getHexProof(leaf);
        console.log("root : " + tree.getHexRoot() + " - " + "proof : " + proof);
        setIsWhitelistedData(false);
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            const walletAddr = String(wallet);
            //const walletAddr = "0x7356a11be0ff8485dee2a3057c22d70f484fa058";
            console.log("ACCOUNT à vérifier = " + walletAddr);

            try {
                const isWhitelisted = await contract.isWhitelisted(walletAddr, proof);
                console.log("Apres verif du contrat = " + isWhitelisted);
                setIsWhitelistedData(isWhitelisted);
                console.log("Storé dans la page = " + isWhitelistedData);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }


    async function setMerkleRootAdmin() {
        console.log("OK START MERKLE")
        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        console.log(tree.toString())
        console.log("root : " + tree.getHexRoot());
    }


    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avant = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apres = " + isConnected);

        }
    }

    async function getAccountsButton() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avantB = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apresB = " + isConnected);

        }
    }

    async function fetchData() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const cost = await contract.publicMintLimit();
                const totalSupply = await contract.totalSupply();
                const totalSupplyWl = await contract.WLLIMIT();
                const collectionSize = await contract.MAXCOLLECTIONSIZE();
                const presaleMintLimit = await contract.presaleMintLimit();
                const presalePrice = await contract.presalePrice(1);
                const publicMintLimit = await contract.publicMintLimit();
                const publicPrice = await contract.publicPrice(1);
                //const balanceOf = await contract.balanceOf(account[0]);

                const object = {
                    "cost": String(cost), "totalSupply": String(totalSupply), "totalSupplyWL": String(totalSupplyWl),
                    "collectionSize": String(collectionSize), "presaleMintLimit": String(presaleMintLimit), "presalePrice": String(presalePrice),
                    "publicMintLimit": String(publicMintLimit), "publicPrice": String(publicPrice),
                    // "balanceOf": String(balanceOf)
                }
                setData(object);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function mint() {
        console.log("NUMBER = " + mintAmount);
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);


            console.log("data.publicPrice = " + data.publicPrice);
            console.log("{data.publicPrice / 10 ** 18} = " + (data.publicPrice / 10 ** 18))
            const valuePrice = data.publicPrice * mintAmount;
            console.log("valuePrice = " + valuePrice);
            console.log("valuePrice é = " + (valuePrice / 10 ** 18));

            //const test = ((data.publicPrice * mintAmount)/ 10 ** 18).toString();
            const test = data.publicPrice * mintAmount;
            console.log("test = " + test);
            try {
                const priceContract = await contract.publicPrice(mintAmount);
                let overrides = {
                    from: accounts[0],
                    //value: data.cost
                    value: test
                }
                const transaction = await contract.mint(mintAmount, { value: priceContract });
                //const transaction = await contract.mint(mintAmount).send({from: accounts[0]});
                // const transaction = await contract.mint(accounts[0], mintAmount, overrides);
                await transaction.wait();
                fetchData();
            }
            catch (err) {
                setError(err.message);
                setTestError(err);
            }
        }
    }

    async function mintWhitelist() {
        console.log("NUMBER = " + mintAmount);
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);

            console.log("data.presalePrice = " + data.presalePrice);
            console.log("{data.presalePrice / 10 ** 18} = " + (data.presalePrice / 10 ** 18))
            const valuePrice = data.presalePrice * mintAmount;
            console.log("valuePrice = " + valuePrice);
            console.log("valuePrice é = " + (valuePrice / 10 ** 18));

            const test = ((data.presalePrice * mintAmount) / 10 ** 18).toString();
            console.log("test = " + test);

            let tab = []
            tokens.map((token) => {
                tab.push(token.address)
            })
            const leaves = tab.map((address) => keccak256(address));
            const tree = new MerkleTree(leaves, keccak256, { sort: true });
            const leaf = keccak256(accounts[0]);
            const proof = tree.getHexProof(leaf);

            try {
                const priceContract = await contract.presalePrice(mintAmount);
                const transaction = await contract.merkleTreeWLMint(mintAmount, proof, { value: priceContract });

                await transaction.wait();
                fetchData();
            }
            catch (err) {
                setError(err.message);
                setTestError(err);
            }
        }
    }

    async function checkPrice(mintAmount) {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const presalePrice = await contract.presalePrice(mintAmount);
                setPrice(presalePrice);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    function handleDecrement() {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    }

    function handleIncrement() {
        if (mintAmount >= 10) return;
        setMintAmount(mintAmount + 1);
    }


/* 
Qcb83#12!SpK"88bx
*/

    return (
        <div className="MintTemporaire">
            <div className='gradient__bg'>
                <Navbar />
            </div>
            {/* <div className='swiitverse__MintWhiteList'>
                <Navbar/>
            </div> */}




            <div className="swiitverse__MintWhiteList section__padding" id="MintWhiteList">


            {isConnected ? (<>
            
            <div className="swiitverse__MintWhiteList-content">
                    <Link to="/tutorialMetaMask">
                        <div className="swiitver__MintWhiteList-buttonHowWallet">
                            <button className="buttonTuto">Comment créer son Wallet</button>
                        </div>
                    </Link>
                    <h1>Test de la connection</h1>
                    <h1>Mint</h1>

                    <div className='gradient'>

                        <div className="test">
                            <div className="flex justify-between items-start">
                                <div className="test1">
                                    <SiEthereum fontSize={21} color="#fff" />
                                </div>
                                {/* <BsInfoCircle fontSize={17} color="#fff" /> */}
                            </div>
                            <div>
                                {/* <p>Balance : {data.balanceOf} / 10</p> */}
                                <p>Votre Adresse de Wallet : </p>
                                <p className="text-white font-light text-sm">
                                    {account[0]}
                                </p>
                                <p className="test22">
                                    Ethereum
                                </p>
                            </div>
                        </div>

                    </div>


                    <p>Mintez vos NFTs Swiit de la collection GENESIIS!</p>
                    {/*                     <p>Select the number of NFT you want to mint during the whitelist :</p>*/}
                    <p>Sélectionner le nombre de NFT que vous souhaitez mint de la collection :</p>
                    <div className="swiitverse__MintWhiteList-content__input">
                        <div className="containerInputMintWhiteList">
                            <button className="buttonNumberMintWL" onClick={handleDecrement}>-</button>
                            <p>{mintAmount}</p>
                            <button className="buttonNumberMintWL" onClick={handleIncrement}>+</button>
                        </div>
                    </div>
                    <p><BsInfoCircle />&nbsp;Détail :</p>
                    <p>Prix : {((data.publicPrice / 10 ** 18) * mintAmount).toFixed(2)} ETH <SiEthereum /></p>
                    {/*<p>Number of mint max during presale(whitelist) : {data.presaleMintLimit} NFTs</p>*/}
                    <p>Nombre de mint maximum : {data.publicMintLimit} NFTs</p>
                    <p>Total Supply : {data.totalSupply} / {data.collectionSize}</p>
                    <div className="swiitver__MintWhiteList-content__inputMint">
                        <button onClick={mint} className="buttonWLMint">MINT your {mintAmount} NFT</button>
                    </div>
                    {error ? (<>
                        <p>{TestError.reason}</p>
                        {/*                     <p>Error : {error}</p>
 */}                    </>) : (
                        <><p></p></>
                    )}
                </div>
            
            </>) : (<>
            
                <p>PAS CONNECTE</p>
                <button onClick={getAccounts()}>Connect MetaMask</button>
            



            </>)

            }

                <div className="swiitverse__MintWhiteList-image">
                    <img src={ai} />
                </div>


            </div>
            <Footer></Footer>
            {/*             <Footer />
 */}        </div>
    )
}

export default MintTemporaire
