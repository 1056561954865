import React from 'react';
import swiitLogo from '../../assets/Swiitverse_logo.svg';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { HashLink as Link } from 'react-router-hash-link';

import './footer.css';


const Footer = () => {
  return (
    <div className="swiitverse__footer section__padding">
      <div className="swiitverse__footer-heading">
        <h1>Vivez en avant première le futur des expériences sportives</h1>
      </div>

      <div className="swiitverse__footer-btn">
        <a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">
          <p>Rejoins le Discord</p>
        </a>
      </div>

      <div className="swiitverse__footer-links">
        <div className="swiitverse__footer-links_logo">
          <img src={swiitLogo} alt="swiitverse_logo" />
          <p>SAS Swiit<br /> All Rights Reserved</p>
        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Liens</h4>
{/*           <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>Contact</p> */}
          <p><a href='https://twitter.com/Swiit_Fit' target="_blank" rel="noreferrer"><BsTwitter color='white' size={27} /></a></p>
        <p><a href='https://www.instagram.com/swiitverse/' target="_blank" rel="noreferrer"><BsInstagram color='white' size={27} /></a></p>
        <p><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer"><FaDiscord color='white' size={27} /></a></p>

        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Entreprise</h4>
          <Link to="/mention-legale#top"><p>Mentions légales</p></Link>
          <Link to="/politque-de-confidentialite#top"><p>Politique de confidentialité</p></Link>
{/*           <p>Terms & Conditions</p>
          <p>Privacy Policy</p> */}
          {/*           <p>Contact</p>*/}
        </div>
        <div className="swiitverse__footer-links_div">
          <h4>Rester en contact</h4>
          <p>100 Rue des Métiers 83140 Six-Fours-les-Plages</p>
          <p>Phone:</p>
          <p>contact@swiit.fit</p>
        </div>
      </div>

      <div className="swiitverse__footer-copyright">
        <p>@2022 SAS Swiit. All rights reserved.</p>
      </div>
    </div>
  )
}

export default Footer;