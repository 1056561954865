import React from 'react'
import Feature from '../../components/feature/Feature';
import './faq.css';

const featuresData = [
  {
    title: 'Valeur du Mint?',
    text: '0,15 ETH + gas fee',
  },
  {
    title: 'Quelles sont nos royalties?',
    text: '10.55%',
  },
  {
    title: 'Nombre Total de NFT:',
    text: '5.555 NFT : 3055 premium / 1388 silver / 722 gold / 277 platimium / 113 Diamond',
  },
  {
    title: 'Blockchain & MarketPlace:',
    text: 'ETHEREUM & OPENSEA',
  },
];

const Faq = () => {
  return (
    <div className="swiitverse__features section__padding" id="faq">
      <div className="swiitverse__features-heading">
        <h1 /* className="gradient__text" */>FAQ:</h1>
        <p>Vous avez encore des questions?</p>
        <p>Le meilleur endroit pour en apprendre plus sur le SwiitVerse est sur notre Discord.</p>
        <p>Il y aura toujours quelqu'un pour vous aider quand vous en aurez besoin.</p>
      </div>
      <div className="swiitverse__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Faq