import React from 'react';
/* import { BsFacebook, BsTwitter, BsInstagram } from 'react-icons/bs';
 *//* import { AiFillTwitterCircle } from 'react-icons/ai';
 */
 import SwiitTeamLogo from '../../assets/teamsphoto/swiitLogo.png';

 import Quentin from '../../assets/teamsphoto/quentin.png';
 import Alexandre from '../../assets/teamsphoto/alexandre.jpeg';
 import Damien from '../../assets/teamsphoto/damien.png';
 import Gaya from '../../assets/teamsphoto/gaya.png';
 import Guillaume from '../../assets/teamsphoto/guillaume.png';
 import Julien from '../../assets/teamsphoto/julien-polo.png';
 import Marine from '../../assets/teamsphoto/marine.png';
 import Mickael from '../../assets/teamsphoto/mickael.png';
 import Olivier from '../../assets/teamsphoto/olivier.jpg';
 import Sabrina from '../../assets/teamsphoto/Sabrina-Boudefar.png';
 import Sullivan from '../../assets/teamsphoto/sullivan.png';
 import Yoann from '../../assets/teamsphoto/yoann-kostovska.png';
 import Zunair from '../../assets/teamsphoto/Zunair.png';
 import Haisum from '../../assets/teamsphoto/Haisum.png';
 import Matteo from '../../assets/teamsphoto/matteo.png';

import './teams.css';

const TeamsEng = () => {
    return (
        <div className='swiitverse__teams section__padding' id='teams'>
        <div className="team-section">
            <div className="inner-width">
                <h1>Ours Teams:</h1>
                <div className="pers">


                    

                    <div className="pe">
                        <img src={Guillaume} alt="guillaume" />
                        <div className="p-name">Guillaume</div>
                        <div className="p-des">CEO Swiit</div>
                        {/* <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Sullivan} alt="sulli"/>
                        <div className="p-name">Sullivan</div>
                        <div className="p-des">Managing partners / CMO</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Quentin} alt="quentin"/>
                        <div className="p-name">Quentin</div>
                        <div className="p-des">CTO Swiit</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={SwiitTeamLogo} alt="swiit" />
                        <div className="p-name">SWIIT TEAM</div>
                        <div className="p-des">+32 people working on Swiit and the SwiitVerse</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>
                </div>

                <div className="pers">


                    <div className="pe">
                        <img src={Gaya} alt="quentin"/>
                        <div className="p-name">Gaelle MORAULT</div>
                        <div className="p-des">Creative director of social networks</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                            <img src={Matteo} alt="sulli" />
                            <div className="p-name">Mattéo Dufour</div>
                            <div className="p-des">Artistic director</div>
                        </div>

                    <div className="pe">
                        <img src={Marine} alt="sulli"/>
                        <div className="p-name">Marine LUCAS</div>
                        <div className="p-des">Partnership manager</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>


                    <div className="pe">
                        <img src={Mickael} alt="swiit" />
                        <div className="p-name">Mickael MALVICINO</div>
                        <div className="p-des">Sales Manager</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>
                </div>

                <div className="pers">


                    <div className="pe">
                        <img src={Olivier} alt="quentin"/>
                        <div className="p-name">Olivier GARONNE</div>
                        <div className="p-des">Accountant</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Sabrina} alt="guillaume" />
                        <div className="p-name">Sabrina BOUDEFAR</div>
                        <div className="p-des">Web3 Strategist, Tokenomics, DAO, Blockchain advisor</div>
                        {/* <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Alexandre} alt="sulli"/>
                        <div className="p-name">Alexandre FERNANDEZ</div>
                        <div className="p-des">Digital Marketer / Community Manager</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>


                    <div className="pe">
                        <img src={Damien} alt="swiit" />
                        <div className="p-name">Damien TREVISIOL</div>
                        <div className="p-des">Sales / Marketing Assistant</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>
                </div>

                <div className="pers">


                <div className="pe">
                        <img src={Julien} alt="guillaume" />
                        <div className="p-name">Julien Polo</div>
                        <div className="p-des">Producer</div>
                        {/* <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Yoann} alt="quentin"/>
                        <div className="p-name">Yoann KOSTOVSKA</div>
                        <div className="p-des">Producer</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Zunair} alt="guillaume" />
                        <div className="p-name">Muhammad Zunair</div>
                        <div className="p-des">CEO Drudotstech / Head Developer</div>
                        {/* <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                    <div className="pe">
                        <img src={Haisum} alt="sulli"/>
                        <div className="p-name">M Haisum Ali</div>
                        <div className="p-des"> Co-founder Drudotstech / Developer</div>
{/*                             <div className="p-sm">
                            <a href="/#"><BsFacebook /></a>
                            <a href="/#"><BsTwitter /></a>
                            <a href="/#"><BsInstagram /></a>
                        </div> */}
                    </div>

                </div>

            </div>
        </div>
    </div>
    )
}

export default TeamsEng