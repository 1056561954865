import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './containers/Home/Home'
import Freelances from './containers/Freelances/Freelances'
import ErrorPage from './components/ErrorPage/ErrorPage'
import { HomeEng, Mint, Whitelist, WhitelistEng } from './containers'
import TutorialMetaMask from './containers/tutorialMetaMask/TutorialMetaMask'
import Terms from './containers/terms/terms'

import './index.css';
import PolitiqueConfidentialite from './containers/terms/politiqueConfidentialite'
import MintDesign from './containers/Mint/MintDesign'
import WhiteListVerification from './containers/Mint/whiteListVerification'
import MintWhiteList from './containers/Mint/MintWhiteList'
import Whitepaper from './containers/whitepaper/Whitepaper'
import WhitepaperEng from './containers/whitepaper/WhitepaperEng'
import MintTemporaire from './containers/Mint/MintTemporaire'
import HomeMint from './containers/Mint/HomeMint'

/* import { Header, ErrorPage } from './components'
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <React.StrictMode>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}>
        </Route>
        <Route path="/freelances" element={<Freelances />}>
        </Route>
        <Route path="/swiitverseEnglish" element={<HomeEng />}>
        </Route>
        <Route path="/whitelist" element={<Whitelist />}>
        </Route>
        <Route path="/whitelistEng" element={<WhitelistEng />}>
        </Route>
        <Route path="/tutorialMetaMask" element={<TutorialMetaMask />}>
        </Route>
        <Route path="/mention-legale" element={<Terms />}>
        </Route>
        <Route path="/politque-de-confidentialite" element={<PolitiqueConfidentialite />}>
        </Route>
        {/* <Route path="/mint" element={<Mint /> }>
        </Route>
        <Route path="/mintd" element={<MintDesign /> }>
        </Route> */}
        {/* <Route path="/beta-whitelist-verification" element={<WhiteListVerification /> }>
        </Route>
        <Route path="/beta-mint-whitelist" element={<MintWhiteList /> }>
        </Route>
        <Route path="/beta-mint-test" element={<MintTemporaire />}>
        </Route>
        <Route path="/whitepaper" element={<Whitepaper /> }>
        </Route>
        <Route path="/whitepaper-english" element={<WhitepaperEng /> }>
        </Route>
        <Route path="/connect-your-wallet" element={<HomeMint /> }>
        </Route> */}


        <Route path="*" element={<ErrorPage />}>
        </Route>
      </Routes>
    </Router>
  </React.StrictMode>
  );
