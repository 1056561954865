import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './navbar.css';
/* import logo from '../../assets/logo.svg';
 */import logoSwiit from '../../assets/Swiitverse_logo.svg';
/* import logoSwiitMini from '../../assets/Swiitverse_logo-mini.svg';
 *///BEM -> Blocl element modifier les noms des class namea voir plus tard
import { FaDiscord } from 'react-icons/fa';
import English from '../../assets/english.png';
import { HashLink as Link } from 'react-router-hash-link';
import { BsTwitter, BsInstagram } from 'react-icons/bs';

const Menu = () => (
  <>
    <p><Link to="/swiitverseEnglish#home">Home</Link>{/* <a href="#home">Home</a> */}</p>
    <p><Link to="/swiitverseEnglish#possibility">Swiit?</Link></p>
    <p><Link to="/swiitverseEnglish#swiitverse">Swiitverse?</Link></p>
    <p><Link to="/swiitverseEnglish#genesiis">Genesiis</Link></p>
    <p><Link to="/swiitverseEnglish#features">Advantages</Link></p>
{/*     <p><Link to="/swiitverseEnglish#roadmap">Roadmap</Link></p>
 */}    <p><Link to="/swiitverseEnglish#faq">FAQ</Link></p>
    <p><Link to="/swiitverseEnglish#teams">Teams</Link></p>
    {/* <p className='hightlight'><Link to="/whitepaper-english#whitepaper"><b>White Paper</b></Link></p> */}
  </>
);

function NavbarEng() {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="swiitverse__navbar">
      <div className="swiitverse__navbar-links">
        <div className="swiitverse__navbar-links_logo">
          <Link to="/swiitverseEnglish">
            <img src={logoSwiit} alt="logo swiit" />
          </Link>
        </div>
        <div className="swiitverse__navbar-links_logoLanguage">
          <Link to="/">
            <img src={English} alt="logo swiit" />
          </Link>
        </div>
        <div className="swiitverse__navbar-links_container">
          <Menu />
        </div>
      </div>
      <div className="swiitverse__navbar-sign">
        <p><a href='https://twitter.com/Swiit_Fit' target="_blank" rel="noreferrer"><BsTwitter color='white' size={27} /></a></p>
        <p><a href='https://www.instagram.com/swiitverse/' target="_blank" rel="noreferrer"><BsInstagram color='white' size={27} /></a></p>

        <p><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer"><FaDiscord color='white' size={27} /></a></p>
        <Link to="/whitelistEng">
          <button type="button">WHITE LIST</button>
        </Link>      </div>
      <div className="swiitverse__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="swiitverse__navbar-menu_container scale-up-center">
            <div className="swiitverse__navbar-menu_container-links">
              <Menu />
            </div>
            <div className="swiitverse__navbar-menu_container-links-sign">
              {/*           <div className="swiitverse__navbar-links_logo">
            <Link to="/swiitverseEnglish"><img src={English} alt="logoEnglish"/>dzdz</Link>
          </div> */}
              <p><a href='https://twitter.com/Swiit_Fit' target="_blank" rel="noreferrer"><BsTwitter color='white' size={27} /></a></p>
              <p><a href='https://www.instagram.com/swiitverse/' target="_blank" rel="noreferrer"><BsInstagram color='white' size={27} /></a></p>
              <p><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer"><FaDiscord color='white' size={27} /></a></p>
              <Link to="/whitelistEng">
                <button type="button">WHITE LIST</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarEng