import { Navbar } from '../../components';
import Footer from '../footer/Footer';

import tuto1 from '../../assets/tuto1.jpg'
import tuto2 from '../../assets/tuto2.jpg'
import tuto3 from '../../assets/tuto3.jpg'
import tuto4 from '../../assets/tuto4.jpg'
import tuto5 from '../../assets/tuto5.jpg'
import tuto6 from '../../assets/tuto6.jpg'
import tuto7 from '../../assets/tuto7.jpg'
import tuto8 from '../../assets/tuto8.jpg'
import tuto9 from '../../assets/tuto9.jpg'
import tuto10 from '../../assets/tuto10.jpg'
import tuto11 from '../../assets/tuto11.png'
import tuto12 from '../../assets/tuto12.png'
import tuto13 from '../../assets/tuto13.png'
import tuto14 from '../../assets/tuto14.png'

import './tutorialMetaMask.css';

const TutorialMetaMask = () => {
  return (
    <div className="TutorialMetaMask">
      <div className='gradient__bg'>
        <Navbar />
      </div>
      <div className="swiitverse__tutorialMetaMask">
        <div className="swiitverse__tutorialMetaMask-form">
          <h1>Comment Créer son Wallet MetaMask</h1>
          <br />
          <label><b>1. Installer MetaMask</b></label>
          <p>Commencer par installer la version de MetaMask adaptée à votre navigateur internet (Chrome, Firefox, Brave ou Edge) ou à votre téléphone (IOS ou Android) en vous rendant sur <a href='https://metamask.io/download/'><u>leur site</u></a> et en cliquant sur « Install MetaMask for… ». Nous vous conseillons d’utiliser le navigateur Google Chrome si vous êtes sur ordinateur car c’est celui que nous utilisons dans ce tutoriel.</p>
          <img src={tuto1} alt="tuto metamask 1"></img>
          <p>Suivez ensuite les instructions et rajoutez l’extension MetaMask à votre navigateur (« Add extension »).</p>
          <br />
          <label><b>2. Créer son portefeuille avec MetaMask</b></label>
          <p>Après l’installation l’écran ci-dessous devrait apparaître. Cliquez sur « Get Started ».</p>
          <img src={tuto2} alt="tuto metamask 2"></img>
          <p>Cliquez sur « Create a Wallet ».</p>
          <img src={tuto3} alt="tuto metamask 3"></img>
          <p>Vous devrez ensuite accepter les conditions d’utilisation en cliquant sur « I agree ».</p>
          <img src={tuto4} alt="tuto metamask 4"></img>
          <p>Tapez et Confirmez votre mot de passe d’au moins 8 caractères et cochez la case pour accepter les termes et conditions. Ce mot de passe vous permettra d’accéder rapidement à votre portefeuille et vous sera demandé régulièrement. Conservez-le donc bien et à l’abri des regards indiscrets (nous vous conseillons de l’écrire sur papier et de ne pas le sauvegarder sur votre ordinateur). Cependant si vous le perdez pas de panique il n’est pas nécessaire pour retrouver l’accès à vos cryptomonnaies. Cliquez ensuite sur « Create ».</p>
          <img src={tuto5} alt="tuto metamask 5"></img>
          <p>Vous pouvez passez la vidéo d’explications en anglais en cliquant sur « Next » car nous allons tout vous expliquer.</p>
          <img src={tuto6} alt="tuto metamask 6"></img>
          <p>Vous entamez à présent l’étape la plus importante de la création de votre portefeuille. Il s’agit en effet de copier votre phrase de récupération (« recovery phrase »). C’est grâce à ces 12 mots (dans l’ordre) et seulement grâce à ces 12 mots que vous pouvez accédez à tout moment à vos cryptomonnaies et de n’importe où. C’est grâce à ses 12 mots que vous pourrez récupérer vos cryptomonnaies si vous perdez ou cassez votre ordinateur ou votre téléphone. Ils sont tout à la fois : votre coffre digital et la clé qui permet d’y accéder. Ils sont vos économies, vos cryptomonnaies. Si vous perdez ces 12 mots, personne ne pourra vous aider et vos cryptomonnaies seront perdues à tout jamais. Si quelqu’un accède à ces 12 mots, il peut vous prendre vos cryptomonnaies.
            <br />
            Il est donc très important que vous gardiez une (et même plusieurs !) copie de cette phrase de récupération. Surtout ne la sauvegardez pas sur un objet connecté à internet, ne l’envoyez pas par email etc… Personne d’autre que vous ne doit pouvoir y accéder. Certaines personnes la grave même dans le métal et la conserve dans un coffre à la banque (scoop : les banques servent encore à quelque chose !). Cependant une copie plastifiée (pour éviter sa dégradation) et conservée en lieu sûr convient parfaitement. Pensez tout de même à faire en sorte qu’elle soit accessible par vos héritiers au cas où il vous arrive quelque chose. Chacun est cependant libre d’acter comme il le souhaite. Vous êtes seuls responsables de vos avoirs, c’est à la fois l’avantage et l’inconvénient d’être sa propre banque.
            <br />
            Pour découvrir votre phrase de récupération cliquez sur le ?.</p>
            <img src={tuto7} alt="tuto metamask 7"></img>
          <p>Notez les 12 mots de votre phrase de récupération dans l’ordre et cliquez sur « Next » :</p>
          <img src={tuto8} alt="tuto metamask 8"></img>
          <p>Pour s’assurer que vous avez bien copié votre phrase de récupération, MetaMask exige que vous la confirmiez en cliquant sur les 12 mots dans l’ordre sur l’écran suivant puis en cliquant sur « Confirm ».</p>
          <img src={tuto9} alt="tuto metamask 9"></img>
          <p>Bravo ! Vous venez de créer votre premier portefeuille de cryptomonnaies en utilisant l’application MetaMask. Vous pouvez cliquer sur « All Done ».</p>
          <img src={tuto10} alt="tuto metamask 10"></img>
          <br />
          <label><b>Epingler l’extension MetaMask dans Google Chrome</b></label>
          <p>Vous pouvez à présent épingler l’application MetaMask dans votre barre URL pour faciliter son utilisation. Cliquez sur l’icône extensions en haut à droite de l’écran et sur l’épingle figurant à côté de MetaMask.</p>
          <img src={tuto11} alt="tuto metamask 11"></img>
          <label><b>Recevoir des cryptomonnaies sur MetaMask</b></label>
          <p>Vous ne pourrez recevoir directement que des cryptomonnaies en provenance de la même blockchain. Pour transférer des tokens d’une blockchain à une autre il vous faudra utiliser ce que l’on appelle des « bridges » et généralement mis à disposition par les jeux et métavers que vous utiliserez.
            <br />
            Par défaut votre portefeuille MetaMask est connecté au « Ethereum Mainnet », c’est à dire à la blockchain Ethereum. Vous pouvez cependant à tout moment le connecter à une autre blockchain comme la BSC (« Binance Smart Chain ») pour recevoir des tokens sur cette blockchain. Si quelqu’un vous a par exemple envoyé des POLCs via la BSC vous ne les verrez apparaître que si vous connectez votre portefeuille à cette dernière. Voici notre article détaillant comment faire.
            <br />
            Pour recevoir des cryptomonnaies en utilisant MetaMask, cliquez sur l’extension MetaMask dans votre barre URL et cliquez ensuite sur les 3 points verticaux encadrés ci-dessous. Ensuite cliquez sur « Account details ».</p>
            <img src={tuto12} alt="tuto metamask 12"></img>
          <p>Un code QR et une adresse ETH s’affichent (Il s’agit de l’adresse ETH de votre portefeuille). Vous pouvez soit scanner le code QR pour générer l’adresse ETH soit directement copier coller cette adresse ETH. C’est sur cette adresse que peuvent vous être envoyés des ETHs ou toutes autres cryptomonnaies/tokens fonctionnant sur la blockchain Ethereum. Vous remarquerez au passage la possibilité d’exporter sa clé privée (« Export Private Key »). Cette clé privée est la version indigeste de votre phrase de récupération et peut aussi être utilisée pour récupérer vos cryptomonnaies. Elle doit donc absolument rester confidentielle.</p>
          <img src={tuto13} alt="tuto metamask 13"></img>
          <label><b>Acheter des ETHs en utilisant MetaMask</b></label>
          <p>Vous pouvez acheter des ETHs directement depuis MetaMask avec les tierces parties Wyre et TRANSAK. Vous pourrez utiliser votre carte de crédit avec wyre et soit une carte de crédit soit un virement bancaire avec TRANSAK. Les commissions de ces tierces parties sont très supérieures à ce que vous payerez sur des bourses centralisées telles que Kraken.</p>
          <img src={tuto14} alt="tuto metamask 14"></img>
{/* 

          <p>https://www.tokize.com/blog/guide-portefeuille-cryptomonnaies/#creer-son-portefeuille-metamask</p> */}
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default TutorialMetaMask
