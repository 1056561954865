import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Swiit from './contracts/Swiit.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';

import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';

import './MintTemporaire.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";


import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";

import ai from '../../assets/mint/Swiit-NFT-Genesiis-cartes.png';


window.Buffer = window.Buffer || Buffer;

const tokens = require("./tokens.json");
const GGaddress = "0xCf75DA7B85Ed75A370710a3d6432BFe7fc064AeC";

function HomeMint() {


    const [error, setError] = useState('');
    const [TestError, setTestError] = useState({});
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);
    const [mintAmount, setMintAmount] = useState(1);
    const [isConnected, setIsConnected] = useState(false);
    const [isWhitelistedData, setIsWhitelistedData] = useState(Boolean);
    const [wallet, setWallet] = useState('');
    const [price, setPrice] = useState('');
    const [balance, setBalance] = useState('');

    useEffect(() => {
        console.log("KKK = " + isConnected);
        //getAccounts();
        console.log("AAA = " + isConnected);
    }, [])

    const handleChange = event => {
        setWallet(event.target.value);
        //console.log('value is:', event.target.value);
    };


    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avant = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apres = " + isConnected);

        }
    }

    async function getAccountsButton() {
       
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avantB = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apresB = " + isConnected);

        
    }




/* 
Qcb83#12!SpK"88bx

Mettre une image de metamask, et dire que les gens doivent ajouter pour ce connecter
*/


    return (
        <div className="MintTemporaire">
            <div className='gradient__bg'>
                <Navbar />
            </div>
            {/* <div className='swiitverse__MintWhiteList'>
                <Navbar/>
            </div> */}




            <div className="swiitverse__MintWhiteList section__padding" id="MintWhiteList">


            {isConnected ? (<>
            
                <h1>Connecté</h1>
            
            </>) : (<>
            
                <p>PAS CONNECTE</p>
                <button onClick={getAccounts()}>Connect MetaMask</button>
                <button onClick={getAccountsButton()}>Connect MetaMask 2</button>

            </>)

            }


            </div>
            <Footer></Footer>
            {/*             <Footer />
 */}        </div>
    )
}

export default HomeMint
