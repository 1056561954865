import React from 'react'
/* import swiitHand from '../../assets/swiithand.png'*/
import { HashLink as Link } from 'react-router-hash-link';

import './reveal.css';

const RevealEng = () => {
  return (
    <div className="section__padding" id="reveal">
      <div className="swiitverse__possibility">
        <div className="swiitverse__possibility-image">
{/*           <video controls autoPlay loop muted>
            <source src={process.env.PUBLIC_URL + '/Reveal.mp4'} type="video/mp4" />
          </video> */}
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ulRzhS7vPl0?&playlist=ulRzhS7vPl0&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowfullscreen></iframe>
          {/*         <img src={swiitHand} alt="possibility" />*/}
        </div>
        <div className="swiitverse__possibility-content">
          <h4><a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">Join the discord</a></h4>
          <h1>GENESIS PRE-REVEAL NFT</h1>
          <p>The black SwiitCard pre reveal.
            <br />48 hours after the start of the public sale, your pre-reveals cards will turn into one of the possible swiit Cards among the 5 main categories, each with a unique number that you will own.
            <br />Can you guess behind which numbers hide the biggest rewards?
          </p>
          <Link to="/whitelistEng#whitelist"><h4>Request early access to the whitelist</h4></Link>
        </div>
      </div>
    </div>
  )
}

export default RevealEng;