import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Swiit from './contracts/Swiit.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';

import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';

import './mintdesign.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";

window.Buffer = window.Buffer || Buffer;


const GGaddress = "0xCf75DA7B85Ed75A370710a3d6432BFe7fc064AeC";

function MintDesign() {


    const [error, setError] = useState('');
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);
    const [mintAmount, setMintAmount] = useState(1);
    const [isConnected, setIsConnected] = useState(Boolean);
    const [isWhitelistedData, setIsWhitelistedData] = useState(Boolean);

    useEffect(() => {
        fetchData();
        getAccounts();
        isWhitelisted();
    }, [])

    async function fetchData() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const cost = await contract.publicMintLimit();
                const totalSupply = await contract.totalSupply();
                const totalSupplyWl = await contract.WLLIMIT();
                const collectionSize = await contract.MAXCOLLECTIONSIZE();
                const presaleMintLimit = await contract.presaleMintLimit();
                const presalePrice = await contract.presalePrice(1);
                const publicMintLimit = await contract.publicMintLimit();
                const publicPrice = await contract.publicPrice(1);

                const object = { "cost": String(cost), "totalSupply": String(totalSupply), "totalSupplyWL": String(totalSupplyWl),
                "collectionSize": String(collectionSize), "presaleMintLimit": String(presaleMintLimit), "presalePrice": String(presalePrice),
                "publicMintLimit": String(publicMintLimit), "publicPrice": String(publicPrice)}
                setData(object);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function mint() {
        console.log("NUMBER = "+mintAmount);
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);


            console.log("data.publicPrice = "+data.publicPrice);
            console.log("{data.publicPrice / 10 ** 18} = " + (data.publicPrice / 10 ** 18))
            const valuePrice = data.publicPrice * mintAmount;
            console.log("valuePrice = "+valuePrice);
            console.log("valuePrice é = "+(valuePrice / 10 ** 18));

            //const test = ((data.publicPrice * mintAmount)/ 10 ** 18).toString();
            const test = data.publicPrice * mintAmount;
            console.log("test = "+ test);
            try {
                const priceContract = await contract.publicPrice(mintAmount);
                let overrides = {
                    from: accounts[0],
                    //value: data.cost
                    value: test
                }
                const transaction = await contract.mint(mintAmount, {value: priceContract});
                //const transaction = await contract.mint(mintAmount).send({from: accounts[0]});
                // const transaction = await contract.mint(accounts[0], mintAmount, overrides);
                await transaction.wait();
                fetchData();
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function withdraw() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);
            try {
                const transaction = await contract.withdraw();
                await transaction.wait();
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function setMerkleRoot() {
        console.log("OK START MERKLE")
        let whitelistAddresses = [
            "0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058",
            "0X5A641E5FB72A2FD9137312E7694D42996D689D99",
            "0XDCAB482177A592E424D1C8318A464FC922E8DE40",
            "0X6E21D37E07A6F7E53C7ACE372CEC63D4AE4B6BD0",
            "0X09BAAB19FC77C19898140DADD30C4685C597620B",
            "0XCC4C29997177253376528C05D3DF91CF2D69061A",
            "0xdD870fA1b7C4700F2BD7f44238821C26f7392148" 
          ];
        
        const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
        const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});

        const rootHash = merkleTree.getRoot();
        console.log('Whitelist Merkle Tree\n', merkleTree.toString());
        console.log("Root Hash: ", rootHash);
        // const claimingAddress = keccak256("0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058");
        // const hexProof = merkleTree.getHexProof(claimingAddress);
        // console.log(hexProof);
        // console.log(merkleTree.verify(hexProof, claimingAddress, rootHash));



        // if (typeof window.ethereum !== 'undefined') {
        //     let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        //     const provider = new ethers.providers.Web3Provider(window.ethereum);
        //     const signer = provider.getSigner();
        //     const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);
        //     try {
        //         const transaction = await contract.setMerkleRoot("");
        //         await transaction.wait();
        //     }
        //     catch (err) {
        //         setError(err.message);
        //     }
        // }
    }

    async function isWhitelisted() {

        let whitelistAddresses = [
            "0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058",
            "0x67aB36379e0EbaF52C618c04A11b11d49B969059",
            "0X5A641E5FB72A2FD9137312E7694D42996D689D99",
            "0XDCAB482177A592E424D1C8318A464FC922E8DE40",
            "0X6E21D37E07A6F7E53C7ACE372CEC63D4AE4B6BD0",
            "0X09BAAB19FC77C19898140DADD30C4685C597620B",
            "0XCC4C29997177253376528C05D3DF91CF2D69061A",
            "0xdD870fA1b7C4700F2BD7f44238821C26f7392148" 
          ];
        
        let addr = "0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058";

        let indexOfArray = whitelistAddresses.indexOf(addr);
        let leafNodes = whitelistAddresses.map(addr => keccak256(addr));
        const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});
        const claimingAddress = leafNodes[indexOfArray];
        const hexProof = merkleTree.getHexProof(claimingAddress);
        console.log(hexProof, 'hexProof');
        console.log("ACCOUNT CO = "+ account);

        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            const walletAddr = String(account);
            console.log("ACCOUNT zaeaeaz = "+walletAddr);
            const test = "0x7356a11be0ff8485dee2a3057c22d70f484fa058";

            try {
                const isWhitelisted = await contract.isWhitelisted(walletAddr, hexProof);
                console.log("AHAHAHHA = " +isWhitelisted);
                const object = isWhitelisted
                setIsWhitelistedData(isWhitelisted);
                console.log("object = "+JSON.stringify(isWhitelisted));
                console.log("test = " + isWhitelistedData);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avant = "+isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apres = "+isConnected);

        }
    }

    function testt () {
        console.log("tu es whilested = " + isWhitelistedData);
    }

    function handleDecrement() {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    }

    function handleIncrement() {
        if (mintAmount >= 3) return;
        setMintAmount(mintAmount + 1);
    }

    // async function connectedAccount() {
    //     if (window.ethereum) {
    //         const accounts = await window.ethereum.request({
    //             method: "eth_requestAccounts",
    //         });
    //         setAccount(accounts)
    //     }
    // }

    return (
        <div className="Home">
            <div className='gradient__bg'>
                <Navbar />
            </div>

            <div className="section__padding" id="possibility">
                {/* <div className="swiitverse__possibility-video">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ulRzhS7vPl0?&playlist=ulRzhS7vPl0&loop=1&autoplay=1&mute=1&controls=1&fullscreen=1" title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; loop; fullscreen" allowFullScreen></iframe>
                </div> */}
                <div className="swiitverse__possibility">
                    <div className="swiitverse__possibility-image">
                        <video controls /*autoPlay*/ loop muted>
                            <source src={process.env.PUBLIC_URL + '/village-digital-Grandformat.mp4'} type="video/mp4" />
                        </video>
                    </div>
                    <div className="swiitverse__possibility-content">


                    {account[0] === "0xd16e91df86fc91567f1a4710e450dd399c3509f5" && <button className="withdraw" onClick={withdraw}>Withdraw Admin</button>}

                        {isConnected ? (
                            <>

                            {account[0] === "0xd16e91df86fc91567f1a4710e450dd399c3509f5" && <>
                            <p>Set whitelist test</p>
                            <button className="setMerkleRoot" onClick={setMerkleRoot}>setMerkleRoot Admin</button>
                            </>}



                            <p>CONNECTE {account[0]}</p>
                            <h1><img src={logoSwiit} alt="logo swiit" /> THE GENESIIS NFT COLLECTION (ETH)</h1>
                        <p>Texte lorem ipsum Texte lorem ipsumTexte lorem ipsumTexte lorem ipsumTexte lorem ipsum</p>
                        {error && <p>{error}</p>}
                        <h1>Mint a SWT NFT!</h1>
                        <p className="count">Status: {data.totalSupply} / {data.collectionSize}</p>
                        <p>-----------------</p>
                         <p>totalSupplyWL : {data.totalSupplyWL}</p>
                        <p>collectionSize: {data.collectionSize}</p>
                        <p>presaleMintLimit: {data.presaleMintLimit}</p>
                        <p>presalePrice: {(data.presalePrice / 10 ** 18)}</p>
                        <p>publicMintLimit: {data.publicMintLimit}</p>
                        <p>publicPrice: {(data.publicPrice / 10 ** 18)}</p>

                        <p>-----------------</p>
                        <button className="isWhitelisted" onClick={isWhitelisted}>isWhitelisted Admin</button>
                        <p>WHITELISTED = {JSON.stringify(isWhitelistedData)}</p>
                        <button className="isWhitelistedzea" onClick={testt}>test </button>
                        <p>test {JSON.stringify(isWhitelistedData)}</p>

                        <p className="cost">Each SWT NFT costs {data.cost / 10 ** 18} eth (excluding gas fee)</p>
                        <div className="containerInput">
                            <button className="buttonNumber" onClick={handleDecrement}>-</button>
                            <p>{mintAmount}</p>
                            <button className="buttonNumber" onClick={handleIncrement}>+</button>
                        </div>
                        <button onClick={mint} className="buttonMint">Buy NFT</button>
                            </>
                        ) : (
                            <>
                            <p>PAS CONNECTE</p>
                            <button onClick={getAccounts()}>Connect MetaMask</button>
                            </>
                        )}
                        {/* <h1><img src={logoSwiit} alt="logo swiit" /> THE GENESIIS NFT COLLECTION (ETH)</h1>
                        <p>Texte lorem ipsum Texte lorem ipsumTexte lorem ipsumTexte lorem ipsumTexte lorem ipsum</p>
                        {error && <p>{error}</p>}
                        <h1>Mint a SWT NFT!</h1>
                        <p className="count">Status: {data.totalSupply} / 20</p>
                        <p className="cost">Each SWT NFT costs {data.cost / 10 ** 18} eth (excluding gas fee)</p>
                        <div className="containerInput">
                            <button className="buttonNumber" onClick={handleDecrement}>-</button>
                            <p>{mintAmount}</p>
                            <button className="buttonNumber" onClick={handleIncrement}>+</button>
                        </div>
                        <button onClick={mint} className="buttonMint">Buy NFT</button>
                     */}
                    
                    
                    </div>



                </div>


            </div>

            <Footer />
        </div>
    )
}

export default MintDesign
