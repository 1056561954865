import React from 'react'
import Feature from '../../components/feature/Feature';
import { HashLink as Link } from 'react-router-hash-link';

import './features.css';

const featuresData = [
  {
    title: 'NFT Premium Member',
    text: 'ETH reward after the sold-out (25 draws for a value per winner of €555) / Card number 55 after the sold-out will unlock the first MVP ring (in real life) / Access to the white list of the next NFT collection / Bonus of SWTcoin at its introduction',
  },
  {
    title: 'NFT Silver Member',
    text: 'ETH reward after the sell-out (15 draws for a value per winner of €1555) / Card number 55 after the sell-out will unlock the first MVP ring (in real life) / Access to the white list of upcoming NFT/Bonus collection of SWTcoin at its introduction',  },
  {
    title: 'NFT Gold Member',
    text: 'ETH reward after the sell-out (10 draws for a value per winner of €2555) / Card number 55 after the sell-out will unlock the first MVP ring (in real life) / Access to the white list of next NFT collection / Bonus of SWTcoin at its introduction / Access to the ticket office and VIP invitation in the Swiitverse',  },
  {
    title: 'NFT Platine Member',
    text: 'ETH reward after the sell-out (3 draws for a value per winner of €5555) / Card number 55 after the sell-out will unlock the first MVP ring (in real life) / Access to the white list of next NFT collection / Bonus of SWTcoin at its introduction / Access to the ticket office and VIP invitation in the Swiitverse / Access to challenges and nfts in the future of the train to earn',  },
  {
    title: 'NFT Diamant Member',
    text: 'ETH reward after the sold-out (2 draws for a value per winner of €10555) / Card number 55 after the sold out will unlock the first MVP ring (in real life) / Access to the white list of next NFT collection / Bonus of SWTcoin at its introduction / Access to the ticket office and VIP invitation in the Swiitverse / Access to challenges and nfts in the future of the train to earn / IRL invitations to Events, Gala & Professional group ',  },
];

const FeaturesEng = () => {
  return (
    <div className="swiitverse__features section__padding" id="features">
      <div className="swiitverse__features-heading">
        <h1>The Benefits obtained by acquiring NFT Genesiis:</h1>
        <Link to="/whitelistEng#whitelist"><p>Request whitelist access</p></Link>
        <p>100K€ in cash back rewards for our carriers after Sold out in the form of cryptocurrencies</p>
      </div>
      <div className="swiitverse__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default FeaturesEng