import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Swiit from './contracts/Swiit.json';
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import { Buffer } from 'buffer';

import { HashLink as Link } from 'react-router-hash-link';
import logoSwiit from '../../assets/Swiit_neg.png';

import './MintWhiteList.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";


import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";

import ai from '../../assets/mint/Swiit-NFT-Genesiis-cartes.png';


window.Buffer = window.Buffer || Buffer;

const tokens = require("./tokens.json");
const GGaddress = "0xCf75DA7B85Ed75A370710a3d6432BFe7fc064AeC";

function MintWhiteList() {


    const [error, setError] = useState('');
    const [TestError, setTestError] = useState({});
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);
    const [mintAmount, setMintAmount] = useState(1);
    const [isConnected, setIsConnected] = useState(Boolean);
    const [isWhitelistedData, setIsWhitelistedData] = useState(Boolean);
    const [wallet, setWallet] = useState('');
    const [price, setPrice] = useState('');

    useEffect(() => {
        fetchData();
        getAccounts();
    }, [])

    const handleChange = event => {
        setWallet(event.target.value);
        //console.log('value is:', event.target.value);
    };

    async function table() {
        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        const leaf = keccak256(wallet);
        //0x7356A11bE0fF8485Dee2A3057C22d70f484Fa058
        const proof = tree.getHexProof(leaf);
        console.log("root : " + tree.getHexRoot() + " - " + "proof : " + proof);
        setIsWhitelistedData(false);
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            const walletAddr = String(wallet);
            //const walletAddr = "0x7356a11be0ff8485dee2a3057c22d70f484fa058";
            console.log("ACCOUNT à vérifier = " + walletAddr);

            try {
                const isWhitelisted = await contract.isWhitelisted(walletAddr, proof);
                console.log("Apres verif du contrat = " + isWhitelisted);
                setIsWhitelistedData(isWhitelisted);
                console.log("Storé dans la page = " + isWhitelistedData);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }


    async function setMerkleRootAdmin() {
        console.log("OK START MERKLE")
        let tab = []
        tokens.map((token) => {
            tab.push(token.address)
        })
        console.log(tab);
        const leaves = tab.map((address) => keccak256(address));
        const tree = new MerkleTree(leaves, keccak256, { sort: true });
        console.log(tree.toString())
        console.log("root : " + tree.getHexRoot());
    }


    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
            console.log("avant = " + isConnected);
            setIsConnected(Boolean(accounts[0]));
            console.log("apres = " + isConnected);

        }
    }

    async function fetchData() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const cost = await contract.publicMintLimit();
                const totalSupply = await contract.totalSupply();
                const totalSupplyWl = await contract.WLLIMIT();
                const collectionSize = await contract.MAXCOLLECTIONSIZE();
                const presaleMintLimit = await contract.presaleMintLimit();
                const presalePrice = await contract.presalePrice(1);
                const publicMintLimit = await contract.publicMintLimit();
                const publicPrice = await contract.publicPrice(1);

                const object = {
                    "cost": String(cost), "totalSupply": String(totalSupply), "totalSupplyWL": String(totalSupplyWl),
                    "collectionSize": String(collectionSize), "presaleMintLimit": String(presaleMintLimit), "presalePrice": String(presalePrice),
                    "publicMintLimit": String(publicMintLimit), "publicPrice": String(publicPrice)
                }
                setData(object);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function mintWhitelist() {
        console.log("NUMBER = " + mintAmount);
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, Swiit.abi, signer);

            console.log("data.presalePrice = " + data.presalePrice);
            console.log("{data.presalePrice / 10 ** 18} = " + (data.presalePrice / 10 ** 18))
            const valuePrice = data.presalePrice * mintAmount;
            console.log("valuePrice = " + valuePrice);
            console.log("valuePrice é = " + (valuePrice / 10 ** 18));

            const test = ((data.presalePrice * mintAmount) / 10 ** 18).toString();
            console.log("test = " + test);

            let tab = []
            tokens.map((token) => {
                tab.push(token.address)
            })
            const leaves = tab.map((address) => keccak256(address));
            const tree = new MerkleTree(leaves, keccak256, { sort: true });
            const leaf = keccak256(accounts[0]);
            const proof = tree.getHexProof(leaf);

            try {
                const priceContract = await contract.presalePrice(mintAmount);
                const transaction = await contract.merkleTreeWLMint(mintAmount, proof, { value: priceContract });

                await transaction.wait();
                fetchData();
            }
            catch (err) {
                setError(err.message);
                setTestError(err);         
            }
        }
    }

    async function checkPrice(mintAmount) {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, Swiit.abi, provider);
            try {
                const presalePrice = await contract.presalePrice(mintAmount);
                setPrice(presalePrice);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    function handleDecrement() {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    }

    function handleIncrement() {
        if (mintAmount >= 10) return;
        setMintAmount(mintAmount + 1);
    }

    return (
        <div className="MintWhiteList">
            <div className='gradient__bg'>
                <Navbar />
            </div>


            <div className="swiitverse__MintWhiteList section__padding" id="MintWhiteList">
                <div className="swiitverse__MintWhiteList-content">
                <Link to="/tutorialMetaMask">
                        <div className="swiitver__MintWhiteList-buttonHowWallet">
                            <button className="buttonTuto">Comment créer son Wallet</button>
                        </div>
                    </Link>
                    <h1>Presale Mint</h1>
                    <p>Mintez vos NFTs Swiit de la collection GENESIIS!</p>
{/*                     <p>Select the number of NFT you want to mint during the whitelist :</p>*/}
                    <p>Sélectionner le nombre de NFT que vous souhaitez mint pour la presale de la collection (whitelist) :</p>
                    <div className="swiitverse__MintWhiteList-content__input">
                        <div className="containerInputMintWhiteList">
                            <button className="buttonNumberMintWL" onClick={handleDecrement}>-</button>
                            <p>{mintAmount}</p>
                            <button className="buttonNumberMintWL" onClick={handleIncrement}>+</button>
                        </div>
                    </div>
                    <p>Prix pendant la presale : {((data.presalePrice / 10 ** 18) * mintAmount).toFixed(2)} ETH <SiEthereum /></p>
                    {/*<p>Number of mint max during presale(whitelist) : {data.presaleMintLimit} NFTs</p>*/}
                    <p>Nombre de mint maximum (whitelist) : {data.presaleMintLimit} NFTs</p>
                    <p>Total Supply for whitelist : {data.totalSupply} / {data.totalSupplyWL}</p>
                    <div className="swiitver__MintWhiteList-content__inputMint">
                        <button onClick={mintWhitelist} className="buttonWLMint">MINT your {mintAmount} NFT</button>
                    </div>
                    {error ? (<>
                    <p>Error: {TestError.reason}</p>
{/*                     <p>Error : {error}</p>
 */}                    </>) : (
                        <><p></p></>
                    )}
                </div>

                <div className="swiitverse__MintWhiteList-image">
                    <img src={ai} />
                </div>



                {account[0] === "0xd16e91df86fc91567f1a4710e450dd399c3509f5" &&
                            <>
                                <p>Set setMerkleRoot Admin</p>
                                <button className="buttonWL" onClick={setMerkleRootAdmin}>Get the ROOT Admin</button>
                            </>
                        }

            </div>

            <Footer />
        </div>
    )
}

export default MintWhiteList
