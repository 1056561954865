import React from 'react'
import './swiitverse.css';

const Swiitverse = () => {
  return (
    <div className="swiitverse__swiitverse section__margin" id="swiitverse">
      {/* <div className="gpt3__whatgpt3-feature">
        <Feature title="Swiitverse" text="La pratique du sport est un enjeu sanitaire fondamentale. 
Enfin le SWIITverse deviendra le premier village digital du Metaverse dans lequel chaque club, coachs, marques, équipementiers, associations sportives auront la possibilité de créer leur propre espace 3.0
" />
      </div> */}
      <div className="swiitverse__swiitverse-feature">
        <div className="swiitverse__swiitverse-title">
          <h1>Swiitverse</h1>
        </div>
        <div className="swiitverse__swiitverse-description">
          <div className="swiitverse__swiitverse-description-title">
            <div />
            <h1>Le Metaverse du sport</h1>
          </div>
          <div className="swiitverse__swiitverse-description-text">
            <p>La pratique du sport est un enjeu sanitaire fondamental.
              <br />Avec le développement du Metaverse, n’y a-t-il pas un enjeu social et sanitaire lié à la pratique du sport « seul » dans son salon ?
              <br />Nous pensons que oui et pour cela nous avons conçu l’idée du SWIITverse
              <br />Il s’agit donc de favoriser et récompenser la pratique du sport dans la vraie vie en créant la passerelle vers le futur Metaverse sportif.
              <br />Nous avons imaginé de nombreuses connexions allant jusqu’au monde du gaming. Tout étant lié, nous pouvons affirmer que l’un de nos objectifs sera d’offrir aux gamers une nouvelle manière de progresser et d’obtenir des avantages dans leurs jeux vidéos, via une pratique physique dans la vraie vie.
              <br />Enfin le SWIITverse deviendra le premier village digital du Metaverse dans lequel chaque club, coach, marque, équipementier, association sportive aura la possibilité de créer son propre espace 3.0
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Swiitverse