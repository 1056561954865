import { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import GreedyGeeseSw from './contracts/GreedyGeeseSw.json';
import img1 from "./img/1.jpeg";
import img2 from "./img/2.jpeg";
import img3 from "./img/3.jpeg";
import img4 from "./img/4.jpeg";
import img5 from "./img/5.jpeg";
import img6 from "./img/6.jpeg";
import img7 from "./img/7.jpeg";
import img8 from "./img/8.jpeg";
import img9 from "./img/9.jpeg";
import img10 from "./img/10.jpeg";

import './mint.css';

import Navbar from "../../components/navbar/Navbar";
import React from "react";
/* import { Link } from 'react-router-dom';
 */
import Footer from "../footer/Footer";

const GGaddress = "0x036Eddd0194558711f3BA980bbA1B53742bF9aF6";

function Mint() {


    const [error, setError] = useState('');
    const [data, setData] = useState({});
    const [account, setAccount] = useState([]);

    useEffect(() => {
        fetchData();
        getAccounts();
    }, [])

    async function fetchData() {
        if (typeof window.ethereum !== 'undefined') {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(GGaddress, GreedyGeeseSw.abi, provider);
            try {
                const cost = await contract.cost();
                const totalSupply = await contract.totalSupply();
                const object = { "cost": String(cost), "totalSupply": String(totalSupply) }
                setData(object);
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function mint() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, GreedyGeeseSw.abi, signer);
            try {
                let overrides = {
                    from: accounts[0],
                    value: data.cost
                }
                const transaction = await contract.mint(accounts[0], 1, overrides);
                await transaction.wait();
                fetchData();
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function withdraw() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(GGaddress, GreedyGeeseSw.abi, signer);
            try {
                const transaction = await contract.withdraw();
                await transaction.wait();
            }
            catch (err) {
                setError(err.message);
            }
        }
    }

    async function getAccounts() {
        if (typeof window.ethereum !== 'undefined') {
            let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            setAccount(accounts);
            console.log(accounts[0]);
        }
    }


    return (
        <div className="Home">
            <div className='gradient__bg'>
                <Navbar />
            </div>
            <div className="swiitverse__whitelist" id='whitelist'>
                
                <div className="swiitverse__whitelist-form">
                    <p>En Cours de Création</p>
                    {account[0] === "0x7356a11be0ff8485dee2a3057c22d70f484fa058" && <button className="withdraw" onClick={withdraw}>Withdraw</button>}

                    <div className="container">
                        <div className="banniere">
                            <img src={img1} alt="img" />
                            <img src={img2} alt="img" />
                            <img src={img3} alt="img" />
                            <img src={img4} alt="img" />
                            <img src={img5} alt="img" />
                            <img src={img6} alt="img" />
                            <img src={img7} alt="img" />
                            <img src={img8} alt="img" />
                            <img src={img9} alt="img" />
                            <img src={img10} alt="img" />
                        </div>
                        {error && <p>{error}</p>}
                        <h1>Mint a SWT NFT!</h1>
                        <p className="count">{data.totalSupply} / 20</p>
                        <p className="cost">Each SWT NFT costs {data.cost / 10 ** 18} eth (excluding gas fee)</p>
                        <button onClick={mint}>BUY one NFT</button>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Mint
