import React, { Component } from 'react';
/* import { BsFacebook, BsTwitter, BsInstagram } from 'react-icons/bs'; */
/* import { AiFillTwitterCircle } from 'react-icons/ai';
 */
import SwiitTeamLogo from '../../assets/teamsphoto/swiitLogo.png';

import Quentin from '../../assets/teamsphoto/quentin.png';
import Alexandre from '../../assets/teamsphoto/alexandre.jpeg';
import Damien from '../../assets/teamsphoto/damien.png';
import Gaya from '../../assets/teamsphoto/gaya.png';
import Guillaume from '../../assets/teamsphoto/guillaume.png';
import Julien from '../../assets/teamsphoto/julien-polo.png';
import Marine from '../../assets/teamsphoto/marine.png';
import Mickael from '../../assets/teamsphoto/mickael.png';
import Olivier from '../../assets/teamsphoto/olivier.jpg';
import Sabrina from '../../assets/teamsphoto/Sabrina-Boudefar.png';
import Sullivan from '../../assets/teamsphoto/sullivan.png';
import Yoann from '../../assets/teamsphoto/yoann-kostovska.png';
import Zunair from '../../assets/teamsphoto/Zunair.png';
import Haisum from '../../assets/teamsphoto/Haisum.png';
import Matteo from '../../assets/teamsphoto/matteo.png';

import './teams.css';
import Modal from '../../components/modal/Modal';

class Teams extends Component {

    constructor() {
        super();
        this.state = {
            show: false,
            showS: false,
            showQ: false

        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.showModalQ = this.showModalQ.bind(this);
        this.hideModalQ = this.hideModalQ.bind(this);
        this.showModalS = this.showModalS.bind(this);
        this.hideModalS = this.hideModalS.bind(this);
    }

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        this.setState({ show: false });
    };

    showModalQ = () => {
        this.setState({ showQ: true });
    };

    hideModalQ = () => {
        this.setState({ showQ: false });
    };

    showModalS = () => {
        this.setState({ showS: true });
    };

    hideModalS = () => {
        this.setState({ showS: false });
    };

    render() {
        return (
            <div className='swiitverse__teams section__padding' id='teams'>
                <div className="team-section">

                    {/* <div class="row">
                    <div class="column">
                        <div class="card">
                            <div class="img-container">
                                <img src={Guillaume} alt="guillaume" />
                            </div>
                        <h3>Guillaume MICHEL DE PIERREDON</h3>
                        <p>CEO</p>
                        </div>
                    </div>
                    <div class="column">
                        <div class="card">
                            <div class="img-container">
                                <img src={Guillaume} alt="guillaume" />
                            </div>
                        <h3>Guillaume MICHEL DE PIERREDON</h3>
                        <p>CEO</p>
                        </div>
                    </div>
                    
                </div> */}



                    {/* <div className="p-sm">
                                <a href="/#"><BsFacebook /></a>
                                <a href="/#"><BsTwitter /></a>
                                <a href="/#"><BsInstagram /></a>
                            </div> */}

                    <div className="inner-width">
                        <h1>Notre équipe</h1>


                        <div className="pers">

                            <div className="pe">
                                <img src={Guillaume} alt="guillaume" />
                                <div className="p-name">Guillaume MICHEL DE PIERREDON</div>
                                <div className="p-des">CEO</div>

{/*                                 <button className='bio-button' type="button" onClick={this.showModal}>
                                    Voir plus
                                </button> */}
                            </div>

                            {/* <Modal show={this.state.show} handleClose={this.hideModal}>
                                <div>
                                    <img className='bio-img' src={Guillaume} alt="sulli" />
                                </div>
                                <h1 className='bio-title'>Guillaume MICHEL DE PIERREDON</h1>
                                <p className='bio'>Guillaume est un coach sportif diplômé spécialisé en préparation sportive et en haltérophilie, qui a été formé à l’université de Toulon. Une fois ses études terminées, il a ouvert sa première box de CrossFit à La Garde, dans le Var.
                                <br />Il a ensuite diversifié ses activités professionnelles en devenant enseignant à l’UFR STAPS de l’université de Toulon. Il est également coach sportif pour des athlètes de haut niveau.
                                <br />Passionné de nouvelles technologies et de gaming, Guillaume apporte au projet SWIIT des innovations liées à la gamification. Chez SWIIT, il occupe le poste de CEO, et gère le développement de la stratégie d’attributs Web3 (NFT, gamification et tokens), et fait la passerelle avec le monde sportif.</p>
                            </Modal> */}

                            <div className="pe">
                                <img src={Sullivan} alt="sulli" />
                                <div className="p-name">Sullivan TRONCHET</div>
                                <div className="p-des">Directeur général / CMO</div>
                                {/* <button className='bio-button' type="button" onClick={this.showModalS}>
                                    Voir plus
                                </button> */}
                            </div>
                            {/* <Modal show={this.state.showS} handleClose={this.hideModalS}>
                                <div>
                                    <img className='bio-img' src={Sullivan} alt="sulli" />
                                </div>
                                <h1 className='bio-title'>Sullivan Tronchet</h1>
                                <p className='bio'>Sullivan a travaillé pendant plusieurs années en tant que développeur de business et propriétaire d’une agence de communication en Suisse. Passionné de rugby, qu’il a longtemps pratiqué en amateur, il est aujourd’hui directeur général de SWIIT.
                                <br />En collaboration avec Guillaume, il développe le branding de la start-up, créant des contenus iconographiques et vidéo. Il assure également la stratégie commerciale et le développement de partenariats corporate avec des marques et des structures sportives.</p>
                            </Modal> */}

                            <div className="pe">
                                <img src={Quentin} alt="quentin" />
                                <div className="p-name">Quentin MICHEL DE PIERREDON</div>
                                <div className="p-des">CTO</div>
                                {/* <button className='bio-button' type="button" onClick={this.showModalQ}>
                                    Voir plus
                                </button> */}
                            </div>
                            {/* <Modal show={this.state.showQ} handleClose={this.hideModalQ}>
                                <div>
                                    <img className='bio-img' src={Quentin} alt="sulli" />
                                </div>
                                <h1 className='bio-title'>Quentin MICHEL DE PIERREDON</h1>
                                <p className='bio'>Ingénieur informatique, Quentin a fait ses études à Epitech Marseille. Il a eu plusieurs expériences dans le milieu de la tech avant de cofonder SWIIT avec Guillaume et Sullivan. CTO de la société, il gère le volet technique, supervisant le développement de l’application avec l’équipe de développement.
                                <br />Également passionné de gaming, il travaille avec Guillaume sur les rouages de la gamification de SWIIT et sur le principe du Move to Earn de l’application.</p>
                            </Modal> */}


                            <div className="pe">
                                <img src={SwiitTeamLogo} alt="swiit" />
                                <div className="p-name">SWIIT TEAM</div>
                                <div className="p-des">+32 personnes qui travaillent sur Swiit et le Swiitverse</div>

                            </div>
                        </div>

                        <div className="pers">
                            <div className="pe">
                                <img src={Gaya} alt="quentin" />
                                <div className="p-name">Gaelle MORAULT</div>
                                <div className="p-des">Directrice de création réseaux sociaux</div>
                            </div>

                            <div className="pe">
                                <img src={Matteo} alt="sulli" />
                                <div className="p-name">Mattéo Dufour</div>
                                <div className="p-des">Directeur artistique</div>
                            </div>


                            <div className="pe">
                                <img src={Marine} alt="sulli" />
                                <div className="p-name">Marine LUCAS</div>
                                <div className="p-des">Responsable partenariats</div>
                            </div>


                            <div className="pe">
                                <img src={Mickael} alt="swiit" />
                                <div className="p-name">Mickael MALVICINO</div>
                                <div className="p-des">Responsable commercial</div>
                            </div>
                        </div>

                        <div className="pers">
                            <div className="pe">
                                <img src={Olivier} alt="quentin" />
                                <div className="p-name">Olivier GARONNE</div>
                                <div className="p-des">Expert Comptable</div>
                            </div>
                            <div className="pe">
                                <img src={Sabrina} alt="guillaume" />
                                <div className="p-name">Sabrina BOUDEFAR</div>
                                <div className="p-des">Web3 Strategist, Tokenomics, DAO, Blockchain advisor</div>
                            </div>
                            <div className="pe">
                                <img src={Alexandre} alt="sulli" />
                                <div className="p-name">Alexandre FERNANDEZ</div>
                                <div className="p-des">Digital Marketer / Community Manager</div>
                            </div>
                            <div className="pe">
                                <img src={Damien} alt="swiit" />
                                <div className="p-name">Damien TREVISIOL</div>
                                <div className="p-des">Assistant Commercial / Marketing</div>
                            </div>
                        </div>



                        <div className="pers">
                            <div className="pe">
                                <img src={Julien} alt="guillaume" />
                                <div className="p-name">Julien Polo</div>
                                <div className="p-des">Réalisateur</div>
                            </div>

                            <div className="pe">
                                <img src={Yoann} alt="quentin" />
                                <div className="p-name">Yoann KOSTOVSKA</div>
                                <div className="p-des">Réalisateur</div>
                            </div>

                            <div className="pe">
                                <img src={Zunair} alt="guillaume" />
                                <div className="p-name">Muhammad Zunair</div>
                                <div className="p-des">CEO Drudotstech / Head Developer</div>

                            </div>

                            <div className="pe">
                                <img src={Haisum} alt="sulli" />
                                <div className="p-name">M Haisum Ali</div>
                                <div className="p-des"> Co-founder Drudotstech / Developer</div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Teams