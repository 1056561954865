import React from 'react'
import './cta.css';

const CTA = () => (
  <div className="swiitverse__cta">
    <div className="swiitverse__cta-content">
      <p>Rejoins le Discord pour participer à l'Aventure</p>
      <h3>Rejoins-nous Aujourd'hui & Commence à explorer les possibilités infinies du sport 3.0</h3>
    </div>
    <div className="swiitverse__cta-btn">
    <a href='https://discord.gg/7KTTt5mpaF' target="_blank" rel="noreferrer">
      <button type="button">Commencez</button>
      </a>
    </div>
  </div>
);

export default CTA;